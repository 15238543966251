import React from 'react';

const Declaration = ({}) => {
  return (
    <div>
      <p class="font-bold text-center pb-4">1. Общие положения</p>
      <p class="pb-2 indent-0">
        1.1. Настоящая Политика в отношении обработки персональных данных (далее – «Политика») подготовлена в соответствии с п. 2 ч .1 ст. 18.1 Федерального
        закона Российской Федерации «О персональных данных» №152-ФЗ от 27 июля 2006 года (далее – «Закон») и определяет позицию юридического лица ООО
        «МЕРИДИАН», ОГРН: 5087746158880, ИНН: 7734592378, юридический адрес: 119071, г. Москва, вн.тер.г. Муниципальный Округ Донской, ул Малая Калужская, д.
        15, стр. 17, (далее – «Компания») в области обработки и защиты персональных данных (далее – «Данные»).
      </p>

      <p class="pb-2 indent-0">
        1.2. Субъектами Данных, обрабатываемых Компанией, являются посетители и пользователи следующих сайтов и приложений Компании (далее Программы):
      </p>

      <ul class="list-disc pl-10 pb-2">
        <li>
          <a href="https://lk.meridian.pro" target="_blank" rel="noopener noreferrer">
            сайт lk.meridian.pro
          </a>
        </li>
        <li>
          <a href="https://lk2.meridian.pro" target="_blank" rel="noopener noreferrer">
            сайт lk2.meridian.pro
          </a>
        </li>
        <li>
          <a href="https://service.meridian.pro" target="_blank" rel="noopener noreferrer">
            сайт service.meridian.pro
          </a>
        </li>
        <li>
          <a href="https://itunes.apple.com/app/mira/id1234567890" target="_blank" rel="noopener noreferrer">
            мобильное приложение MIRA для iOS
          </a>
        </li>
        <li>
          <a href="https://play.google.com/store/apps/details?id=com.mira.android" target="_blank" rel="noopener noreferrer">
            мобильное приложение MIRA для Android
          </a>
        </li>
        <li>
          <a href="https://cloud.meridian.pro" target="_blank" rel="noopener noreferrer">
            облачный сервер ООО «МЕРИДИАН»
          </a>
        </li>
      </ul>

      <p class="font-bold text-center py-4">2. Область применения</p>
      <p class="pb-2 indent-0">2.1. Настоящая Политика распространяется на Данные, полученные как до, так и после ввода в действие настоящей Политики.</p>
      <p class="pb-2 indent-0">
        2.2. Понимая важность и ценность Данных, а также заботясь о соблюдении конституционных прав граждан Российской Федерации и граждан других государств,
        Компания обеспечивает надежную защиту Данных.
      </p>

      <p class="font-bold text-center py-4">3. Определения</p>
      <p class="pb-2 indent-0">
        3.1. Под Данными понимается любая информация, относящаяся к прямо или косвенно определенному, или определяемому физическому лицу (гражданину), т.е. к
        такой информации, в частности, относятся: фамилия, имя, отчество, адрес регистрации/отправки корреспонденции, электронная почта, номер телефона, лицевой
        счет.
      </p>
      <p class="pb-2 indent-0">
        3.2. Под обработкой Данных понимается любое действие (операция) или совокупность действий (операций) с Данными, совершаемых с использованием средств
        автоматизации и/или без использования таких средств. К таким действиям (операциям) относятся: сбор, запись, систематизация, накопление, хранение,
        уточнение (обновление, изменение), извлечение, использование, передача (распространение, предоставление, доступ), обезличивание, блокирование, удаление,
        уничтожение Данных.
      </p>
      <p class="pb-2 indent-0">
        3.3. Под безопасностью Данных понимается защищенность Данных от неправомерного и/или несанкционированного доступа к ним, уничтожения, изменения,
        блокирования, копирования, предоставления, распространения Данных, а также от иных неправомерных действий в отношении Данных.
      </p>

      <p class="font-bold text-center py-4">4. Правовые основания и цели обработки Данных</p>

      <p class="pb-2 indent-0">
        4.1. Обработка и обеспечение безопасности Данных в Компании осуществляется в соответствии с требованиями Конституции Российской Федерации, Закона,
        Трудового кодекса Российской Федерации, подзаконных актов, других определяющих случаи и особенности обработки Данных федеральных законов Российской
        Федерации, руководящих и методических документов ФСТЭК России и ФСБ России.
      </p>
      <p class="pb-2 indent-0">4.2. Компания осуществляет обработку Данных субъектов в следующих целях: </p>

      <ul class="list-disc pl-10 pb-2">
        <li>исполнения договора или договора-оферты, в т.ч. заключенного дистанционным способом;</li>
        <li>возмездного и безвозмездного оказания услуг, а также для осуществления взаиморасчетов;</li>
        <li>
          осуществления, возложенных на Компанию законодательством Российской Федерации функций, полномочий и обязанностей в соответствии с федеральными
          законами, в том числе, но не ограничиваясь: Гражданским кодексом Российской Федерации, Налоговым кодексом Российской Федерации, Федеральным законом от
          27.07.2006 г. № 152-ФЗ «О персональных данных», Федеральным законом от 07.02.1992 №2300-1 «О защите прав потребителей», Федеральным законом от
          21.11.1996 г. № 129-ФЗ «О бухгалтерском учете», Федеральным законом от 29.11.2010 г. № 326-ФЗ «Об обязательном медицинском страховании в Российской
          Федерации»,
        </li>
        <li>предоставления информации по товарам/услугам, проходящим акциям и специальным предложениям;</li>
        <li>анализа качества предоставляемого Компанией сервиса и улучшению качества обслуживания клиентов Компании.</li>
      </ul>

      <p class="font-bold text-center py-4">5. Принципы и условия обработки Данных</p>

      <p class="pb-2 indent-0">5.1. При обработке Данных Компания придерживается следующих принципов:</p>

      <ul class="list-disc pl-10 pb-2">
        <li>обработка Данных осуществляется на законной и справедливой основе;</li>

        <li>
          Данные не раскрываются третьим лицам и не распространяются без согласия субъекта Данных, за исключением случаев, требующих раскрытия Данных для
          функционирования Программ, по запросу уполномоченных государственных органов, судопроизводства;
        </li>

        <li>определение конкретных законных целей до начала сбора и обработки Данных;</li>

        <li>ведется сбор только тех Данных, которые являются необходимыми и достаточными для заявленной цели обработки;</li>

        <li>обработка Данных ограничивается достижением конкретных, заранее определенных и законных целей;</li>

        <li>
          обрабатываемые Данные подлежат уничтожению или обезличиванию по достижению целей обработки или в случае утраты необходимости в достижении этих целей,
          если иное не предусмотрено федеральным законом.
        </li>
      </ul>

      <p class="pb-2 indent-0">5.2. Компания не может включать или передавать Данные субъектов в общедоступные источники Данных.</p>
      <p class="pb-2 indent-0">
        5.3. Компания не осуществляет обработку Данных, касающихся расовой, национальной принадлежности, политических взглядов, религиозных, философских и иных
        убеждений, интимной жизни, членства в общественных объединениях, в том числе в профессиональных союзах.
      </p>
      <p class="pb-2 indent-0">
        5.4. Биометрические Данные (сведения, которые характеризуют физиологические и биологические особенности человека, на основании которых можно установить
        его личность и которые используются оператором для установления личности субъекта Данные) в Компании не обрабатываются.
      </p>
      <p class="pb-2 indent-0">5.5. Компания не осуществляет трансграничную передачу Данных.</p>
      <p class="pb-2 indent-0">
        5.6. В случаях, установленных законодательством Российской Федерации, Компания вправе осуществлять передачу Данных третьим лицам (федеральной налоговой
        службе, государственному пенсионному фонду, следственным и иным государственным органам) в случаях, предусмотренных законодательством Российской
        Федерации.
      </p>
      <p class="pb-2 indent-0">
        5.7. Компания вправе поручить обработку Данных субъектов Данных третьим лицам с согласия субъекта Данных, на основании заключаемого с этими лицами
        договора, в том числе при согласии с пользовательским соглашением и политики обработки персональных данных размещенных в интерфейсе Программ.
      </p>
      <p class="pb-2 indent-0">
        5.8. Лица, осуществляющие обработку Данных на основании заключаемого с Компанией договора (поручения оператора), обязуются соблюдать принципы и правила
        обработки и защиты Данных, предусмотренные Законом. Для каждого третьего лица в договоре определяются перечень действий (операций) с Данными, которые
        будут совершаться третьим лицом, осуществляющим обработку Данных, цели обработки, устанавливается обязанность такого лица соблюдать конфиденциальность и
        обеспечивать безопасность Данных при их обработке, указываются требования к защите обрабатываемых Данных в соответствии с Законом.
      </p>
      <p class="pb-2 indent-0">
        5.9. В целях исполнения требований действующего законодательства Российской Федерации и своих договорных обязательств обработка Данных в Компании
        осуществляется в электронном виде с использованием средств автоматизации. Совокупность операций обработки включает сбор, запись, систематизацию,
        накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (предоставление, доступ), обезличивание, блокирование,
        удаление, уничтожение Данных.
      </p>
      <p class="pb-2 indent-0">
        5.10. В Компании запрещается принятие на основании исключительно автоматизированной обработки Данных решений, порождающих юридические последствия в
        отношении субъекта Данных или иным образом затрагивающих его права и законные интересы, за исключением случаев, предусмотренных законодательством
        Российской Федерации.
      </p>

      <p class="font-bold text-center py-4">6. Права и обязанности субъектов Данных, а также Компании в части обработки Данных</p>
      <p class="pb-2 indent-0">6.1. Субъект, Данные которого обрабатываются Компанией, имеет право получать от Компании:</p>

      <ul class="list-disc pl-10 pb-2">
        <li>подтверждение факта обработки Данных и сведения о наличии Данных, относящихся к соответствующему субъекту Данных;</li>
        <li>сведения о правовых основаниях и целях обработки Данных;</li>
        <li>сведения о применяемых Компанией способах обработки Данных;</li>
        <li>сведения о наименовании и местонахождении Компании;</li>
        <li>
          сведения о лицах за исключением работников Компании, которые имеют доступ к Данным или которым могут быть раскрыты Данные на основании договора с
          Компанией или на основании федерального закона;
        </li>
        <li>
          перечень обрабатываемых Данных, относящихся к субъекту Данных, и информацию об источнике их получения, если иной порядок предоставления таких Данных
          не предусмотрен федеральным законом;
        </li>
        <li>сведения о сроках обработки Данных, в том числе о сроках их хранения;</li>
        <li>сведения о порядке осуществления субъектом Данных прав, предусмотренных Законом;</li>
        <li>иные сведения, предусмотренные Законом или другими нормативно-правовыми актами Российской Федерации;</li>
      </ul>

      <p class="pb-2 indent-0">6.2. Субъект, Данные которого обрабатываются Компанией, имеет право требовать от Компании:</p>
      <ul class="list-disc pl-10 pb-2">
        <li>
          уточнения своих Данных, их блокирования или уничтожения в случае, если Данные являются неполными, устаревшими, неточными, незаконно полученными или не
          являются необходимыми для заявленной цели обработки;
        </li>
        <li>отозвать свое согласие на обработку Данных в любой момент;</li>
        <li>устранения неправомерных действий Компании в отношении его Данных;</li>
        <li>
          обжаловать действия или бездействие Компании в Федеральную службу по надзору в сфере связи, информационных технологий и массовых коммуникаций
          (Роскомнадзор) или в судебном порядке в случае, если субъект Данных считает, что Компания осуществляет обработку его Данных с нарушением требований
          Закона или иным образом нарушает его права и свободы.
        </li>
      </ul>
      <p class="pb-2 indent-0">6.3. Компания в процессе обработки Данных обязана:</p>
      <ul class="list-disc pl-10 pb-2">
        <li>
          предоставлять субъекту Данных по его запросу информацию, касающуюся обработки его Данных, либо на законных основаниях предоставить отказ в течение
          тридцати дней с даты получения запроса субъекта Данных или его представителя;
        </li>
        <li>
          разъяснить субъекту Данных юридические последствия отказа предоставить Данные, если предоставление Данных является обязательным в соответствии с
          федеральным законом;
        </li>
        <li>
          принимать необходимые правовые, организационные и технические меры или обеспечивать их принятие для защиты Данных от неправомерного или случайного
          доступа к ним, уничтожения, изменения, блокирования, копирования, предоставления, распространения Данных, а также от иных неправомерных действий в
          отношении Данных;
        </li>
        <li>
          опубликовать в сети Интернет и обеспечить неограниченный доступ с использованием сети Интернет к документу, определяющему его политику в отношении
          обработки Данных, к сведениям о реализуемых требованиях к защите Данных;
        </li>
        <li>
          предоставить субъектам Данных и/или их представителям безвозмездно возможность ознакомления с Данными при обращении с соответствующим запросом в
          течение 30 дней с даты получения подобного запроса;
        </li>
        <li>
          осуществить блокирование неправомерно обрабатываемых Данных, относящихся к субъекту Данных, или обеспечить их блокирование (если обработка Данных
          осуществляется другим лицом, действующим по поручению Компании) с момента обращения или получения запроса на период проверки, в случае выявления
          неправомерной обработки Данных при обращении субъекта Данных или его представителя либо по запросу субъекту Данных или его представителя, либо
          уполномоченного органа по защите прав субъектов персональных данных;
        </li>
        <li>
          уточнить Данные либо обеспечить их уточнение (если обработка Данных осуществляется другим лицом, действующим по поручению Компании) в течение 7
          рабочих дней со дня представления сведений и снять блокирование Данных, в случае подтверждения факта неточности Данных на основании сведений,
          представленных субъектом Данных или его представителем;
        </li>
        <li>
          прекратить неправомерную обработку Данных или обеспечить прекращение неправомерной обработки Данных лицом, действующим по поручению Компании, в случае
          выявления неправомерной обработки Данных, осуществляемой Компанией или лицом, действующим на основании договора с Компанией, в срок, не превышающий 3
          рабочих дней с даты этого выявления;
        </li>
        <li>
          прекратить обработку Данных или обеспечить ее прекращение (если обработка Данных осуществляется другим лицом, действующим по договору с Компанией) и
          уничтожить Данные или обеспечить их уничтожение (если обработка Данных осуществляется другим лицом, действующим по договору с Компанией) по достижения
          цели обработки Данных, если иное не предусмотрено договором, стороной которого, выгодоприобретателем или поручителем по которому является субъект
          Данных, в случае достижения цели обработки Данных; прекратить обработку Данных или обеспечить ее прекращение и уничтожить Данные или обеспечить их
          уничтожение в случае отзыва субъектом Данных согласия на обработку Данных, если Компания не вправе осуществлять обработку Данных без согласия субъекта
          Данных;
        </li>
        <li>
          вести журнал учета обращений субъектов Данных, в котором должны фиксироваться запросы субъектов Данных на получение Данных, а также факты
          предоставления Данных по этим запросам.
        </li>
      </ul>

      <p class="font-bold text-center py-4">7. Требования к защите Данных</p>
      <p class="pb-2 indent-0">
        7.1. Компания при обработке Данных принимает необходимые правовые, организационные и технические меры для защиты Данных от неправомерного и/или
        несанкционированного доступа к ним, уничтожения, изменения, блокирования, копирования, предоставления, распространения Данных, а также от иных
        неправомерных действий в отношении Данных.
      </p>
      <p class="pb-2 indent-0">7.2. К таким мерам в соответствии с Законом, в частности, относятся:</p>
      <ul class="list-disc pl-10 pb-2">
        <li>назначение лица, ответственного за организацию обработки Данных, и лица, ответственного за обеспечение безопасности Данных;</li>
        <li>разработка и утверждение локальных актов по вопросам обработки и защиты Данных;</li>
        <li>применение правовых, организационных и технических мер по обеспечению безопасности Данных:</li>
        <li>определение угроз безопасности Данных при их обработке в информационных системах персональных данных;</li>
        <li>
          применение организационных и технических мер по обеспечению безопасности Данных при их обработке в информационных системах персональных данных,
          необходимых для выполнения требований к защите Данных, исполнение которых обеспечивает установленные Правительством Российской Федерации уровни
          защищенности Данных;
        </li>
        <li>применение прошедших в установленном порядке процедуру оценки соответствия средств защиты информации;</li>
        <li>оценка эффективности принимаемых мер по обеспечению безопасности Данных до ввода в эксплуатацию информационной системы персональных данных;</li>
        <li>учет машинных носителей Данных, если хранение Данных осуществляется на машинных носителях;</li>
        <li>обнаружение фактов несанкционированного доступа к Данным и принятие мер по недопущению подобных инцидентов в дальнейшем;</li>
        <li>восстановление Данных, модифицированных или уничтоженных вследствие несанкционированного доступа к ним;</li>
        <li>
          установление правил доступа к Данным, обрабатываемым в информационной системе персональных данных, а также обеспечение регистрации и учета всех
          действий, совершаемых с Данными в информационной системе персональных данных.
        </li>
        <li>контроль за принимаемыми мерами по обеспечению безопасности Данных и уровнем защищенности информационных систем персональных данных;</li>
        <li>
          оценка вреда, который может быть причинен субъектам Данных в случае нарушения требований Закона, соотношение указанного вреда и принимаемых Компанией
          мер, направленных на обеспечение выполнения обязанностей, предусмотренных Законом;
        </li>
        <li>соблюдение условий, исключающих несанкционированный доступ к материальным носителям Данных и обеспечивающих сохранность Данных;</li>
        <li>
          ознакомление работников Компании, непосредственно осуществляющих обработку Данных, с положениями законодательства Российской Федерации о Данных, в том
          числе с требованиями к защите Данных, локальными актами по вопросам обработки и защиты Данных, и обучение работников Компании.
        </li>
      </ul>

      <p class="font-bold text-center py-4">8. Сроки обработки (хранения) Данных</p>
      <p class="pb-2 indent-0">
        8.1. Сроки обработки (хранения) Данных определяются исходя из целей обработки Данных, в соответствии со сроком действия договора с субъектом Данных,
        требованиями федеральных законов, требованиями операторов Данных, по поручению которых Компания осуществляет обработку Данных, основными правилами
        работы архивов организаций, сроками исковой давности.
      </p>
      <p class="pb-2 indent-0">
        8.2. Данные, срок обработки (хранения) которых истек, должны быть уничтожены, если иное не предусмотрено федеральным законом. Хранение Данных после
        прекращения их обработки допускается только после их обезличивания.
      </p>
      <p class="font-bold text-center py-4">9. Порядок получения разъяснений по вопросам обработки Данных</p>
      <p class="pb-2 indent-0">
        9.1. Лица, чьи Данные обрабатываются Компанией, могут получить разъяснения по вопросам обработки своих Данных, обратившись лично в Компанию или направив
        соответствующий письменный запрос по адресу: 1119071, г. Москва, вн.тер.г. Муниципальный Округ Донской, ул Малая Калужская, д. 15, стр. 17 , ООО
        "МЕРИДИАН".
      </p>
      <p class="pb-2 indent-0">9.2. В случае направления официального запроса в Компанию в тексте запроса необходимо указать:</p>

      <ul class="list-disc pl-10 pb-2">
        <li>фамилию, имя, отчество субъекта Данных или его представителя;</li>
        <li>учетную запись (EMAIL), подтверждающую наличие у субъекта Данных отношений с Компанией;</li>
        <li>информацию для обратной связи с целью направления Компанией ответа на запрос;</li>
        <li>подпись субъекта Данных или его уполномоченного представителя.</li>
      </ul>

      <p class="pb-2 indent-0">
        Если запрос отправляется в электронном виде, то он должен быть оформлен в виде электронного документа и подписан электронной подписью в соответствии с
        законодательством Российской Федерации.
      </p>
      <p class="font-bold text-center py-4">10. Особенности обработки и защиты Данных, собираемых Компанией с использованием сети Интернет</p>
      <p class="pb-2 indent-0">10.1. Компания обрабатывает Данные, поступающие от пользователей через интерфейс Программ (п.1.2).</p>
      <p class="pb-2 indent-0">10.2. Предоставляемые Данные</p>

      <ul class="list-disc pl-10 pb-2">
        <li>фамилия;</li>
        <li>имя;</li>
        <li>отчество;</li>
        <li>адрес регистрации/отправки корреспонденции;</li>
        <li>электронная почта;</li>
        <li>номер телефона;</li>
        <li>лицевой счет;</li>
        <li>код плательщика.</li>
      </ul>

      <p class="pb-2 indent-0">10.3. Компания может автоматизированным способом собирать и обрабатывать сведения, не являющимися персональными данными:</p>
      <ul class="list-disc pl-10 pb-2">
        <li>
          информацию об интересах пользователей на основе введенных поисковых запросов пользователей Программ с целью последующего предоставления актуальной
          информации клиентам Компании при использовании Программ;
        </li>
        <li>
          информацию о поведении пользователей в Программах с целью обобщения и анализа информации, о том какие разделы Программ пользуются наибольшим спросом,
          содержат ошибки, имеют плохие показатели юзабилити и т.д.
        </li>
      </ul>
      <p class="pb-2 indent-0">
        Компания автоматически получает некоторые виды информации, генерируемой в процессе взаимодействия пользователей с Программами: веб-протоколы, куки,
        веб-отметки.
      </p>
      <p class="pb-2 indent-0">
        При этом веб-отметки, куки и другие мониторинговые технологии не дают возможность автоматически получать Данные. Если пользователь Программ по своему
        усмотрению предоставляет свои Данные, например, при заполнении формы регистрации или при отправке электронного письма, то только тогда запускаются
        процессы автоматического сбора информации для совершенствования взаимодействия с пользователями.
      </p>
      <p class="pb-2 indent-0">10.4. Использование Данных</p>
      <p class="pb-2 indent-0">
        Компания вправе пользоваться предоставленными Данными в соответствии с заявленными целями их сбора при наличии согласия субъекта Данных, если такое
        согласие требуется в соответствии с требованиями законодательства Российской Федерации в области Данных.
      </p>
      <p class="pb-2 indent-0">
        Полученные Данные в обобщенном и обезличенном виде могут использоваться для лучшего понимания потребностей покупателей товаров и услуг, реализуемых
        Компанией и улучшения качества обслуживания.
      </p>
      <p class="pb-2 indent-0">10.5. Передача Данных</p>
      <p class="pb-2 indent-0">
        Компания может поручать обработку Данных третьим лицам исключительно с согласия субъекта Данных. Также Данные могут передаваться третьим лицам в
        качестве ответа на правомерные запросы уполномоченных государственных органов, в соответствии с законами, решениями суда и пр.
      </p>
      <p class="pb-2 indent-0">
        Данные не могут передаваться третьим лицам для маркетинговых, коммерческих и иных аналогичных целей, за исключением случаев получения предварительного
        согласия субъекта Данных.
      </p>
      <p class="pb-2 indent-0">
        10.6. Программы могут содержать ссылки на иные веб-ресурсы, где может находиться полезная и интересная для пользователей информация. При этом действие
        настоящей Политики не распространяется на такие иные веб-ресурсы. Пользователям, переходящим по ссылкам на другие веб-ресурсы, рекомендуется
        ознакомиться с их политиками об обработке Данных.
      </p>
      <p class="pb-2 indent-0">
        10.7. Пользователь Программ может в любое время отозвать свое согласие на обработку Данных, направив сообщение на адрес электронной почты Компании:
        info@meridian.pro, либо направив письменное уведомление по адресу: 119071, г. Москва, вн.тер.г. Муниципальный Округ Донской, ул Малая Калужская, д. 15,
        стр. 17 , ООО "МЕРИДИАН". После получения такого сообщения обработка Данных пользователя будет прекращена, а его Данные будут удалены, за исключением
        случаев, когда обработка может быть продолжена в соответствии с законодательством.
      </p>
      <p class="font-bold text-center py-4">11. Заключительные положения</p>
      <p class="pb-2 indent-0">
        Настоящая Политика является локальным нормативным актом Компании. Настоящая Политика является общедоступной. Общедоступность настоящей Политики
        обеспечивается публикацией ссылки на неё в Программах Компании.
      </p>
      <p class="pb-2 indent-0">Настоящая Политика может быть пересмотрена в любом из следующих случаев:</p>

      <ul class="list-disc pl-10 pb-2">
        <li>при изменении законодательства Российской Федерации в области обработки и защиты персональных данных;</li>
        <li>в случаях получения предписаний от компетентных государственных органов на устранение несоответствий, затрагивающих область действия Политики;</li>
        <li>по решению руководства Компании;</li>
        <li>при изменении целей и сроков обработки Данных;</li>
        <li>при изменении организационной структуры, структуры информационных и/или телекоммуникационных систем (или введении новых);</li>
        <li>при применении новых технологий обработки и защиты Данных (в т. ч. передачи, хранения);</li>
        <li>
          при появлении необходимости в изменении процесса обработки Данных, связанной с деятельностью Компании. В случае неисполнения положений настоящей
          Политики Компания и ее работники несут ответственность в соответствии с действующим законодательством Российской Федерации. Контроль исполнения
          требований настоящей Политики осуществляется лицами, ответственными за организацию обработки Данных Компании, а также за безопасность персональных
          данных
        </li>
      </ul>
    </div>
  );
};

export default Declaration;
