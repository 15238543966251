import React from 'react';

const ToggleHighlightButtons = ({ title1, title2, btnCb, setHighlighted, highlighted, disabled = false, type = 'button' }) => {
  const handleClick = () => {
    btnCb();
    setHighlighted(!highlighted);
  };

  return (
    <>
      <button
        id={1}
        disabled={disabled}
        onClick={handleClick}
        type={type}
        className={`w-full truncate font-semibold py-2 px-4 rounded-full ${!highlighted ? 'bg-orange-500 text-white' : 'bg-gray-100 hover:bg-gray-200 text-gray-900'}`}>
        {title1}
      </button>
      <button
        id={2}
        disabled={disabled}
        onClick={handleClick}
        type={type}
        className={`w-full truncate font-semibold py-2 px-4 rounded-full ${highlighted ? 'bg-orange-500 text-white' : 'bg-gray-100 hover:bg-gray-200 text-gray-900'}`}>
        {title2}
      </button>
    </>
  );
};

export default ToggleHighlightButtons;
