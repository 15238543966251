import React from 'react';
import AlertAccent from '../components/AlertAccent';
import AutorenewOutlinedIcon from '@mui/icons-material/AutorenewOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import AccessAlarmSharpIcon from '@mui/icons-material/AccessAlarmSharp';
import SignalWifiBadSharpIcon from '@mui/icons-material/SignalWifiBadSharp';
import PhonelinkEraseIcon from '@mui/icons-material/PhonelinkErase';
import OpacityIcon from '@mui/icons-material/Opacity';
import BatteryAlertIcon from '@mui/icons-material/BatteryAlert';
import WavesIcon from '@mui/icons-material/Waves';
import ReadMoreIcon from '@mui/icons-material/ReadMore';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import SignalCellular from '@mui/icons-material/SignalCellularConnectedNoInternet2Bar';
import FastRewindIcon from '@mui/icons-material/FastRewind';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

const devStatuses = {
  controller: {
    activation: {
      name: 'Активирован',
      icon: CheckCircleOutlineIcon,
      istyle: 'fill-green-500 w-6 sm:w-6 xl:w-8 2xl:w-8 h-6 sm:h-6 xl:h-7 2xl:h-7',
    },
    access: {
      name: 'Нарушение доступа',
      icon: AccessAlarmSharpIcon,
      istyle: 'fill-red-500 w-6 sm:w-6 xl:w-8 2xl:w-8 h-6 sm:h-6 xl:h-7 2xl:h-7',
    },
    badnet: {
      name: 'Плохая связь',
      icon: SignalCellular,
      istyle: 'fill-red-500 w-6 sm:w-6 xl:w-8 2xl:w-8 h-6 sm:h-6 xl:h-7 2xl:h-7',
    },
    break: {
      name: 'Поломка',
      icon: PhonelinkEraseIcon,
      istyle: 'fill-red-500 w-6 sm:w-6 xl:w-8 2xl:w-8 h-6 sm:h-6 xl:h-7 2xl:h-7',
    },
    leakage: {
      name: 'Утечка',
      icon: OpacityIcon,
      istyle: 'fill-red-500 w-6 sm:w-6 xl:w-8 2xl:w-8 h-6 sm:h-6 xl:h-7 2xl:h-7',
    },
    lowbat: {
      name: 'Низкий заряд батареи',
      icon: BatteryAlertIcon,
      istyle: 'fill-red-500 w-6 sm:w-6 xl:w-8 2xl:w-8 h-6 sm:h-6 xl:h-7 2xl:h-7',
    },
    magnet: {
      name: 'Сработка магнитного поля',
      icon: WavesIcon,
      istyle: 'fill-red-500 w-6 sm:w-6 xl:w-8 2xl:w-8 h-6 sm:h-6 xl:h-7 2xl:h-7',
    },
    reverse: {
      name: 'Обратный поток',
      icon: FastRewindIcon,
      istyle: 'fill-red-500 w-6 sm:w-6 xl:w-8 2xl:w-8 h-6 sm:h-6 xl:h-7 2xl:h-7',
    },
  },

  counter: {
    break: {
      name: 'Обрыв',
      icon: LinkOffIcon,
      istyle: 'fill-red-500 w-6 sm:w-6 xl:w-8 2xl:w-8 h-6 sm:h-6 xl:h-7 2xl:h-7',
    },
  },

  sensor: {
    alarm: {
      name: 'Сработка',
      icon: NotificationsActiveIcon,
      istyle: 'fill-red-500 w-6 sm:w-6 xl:w-8 2xl:w-8 h-6 sm:h-6 xl:h-7 2xl:h-7',
    },
    break: {
      name: 'Обрыв',
      icon: LinkOffIcon,
      istyle: 'fill-red-500 w-6 sm:w-6 xl:w-8 2xl:w-8 h-6 sm:h-6 xl:h-7 2xl:h-7',
    },
    dataerr: {
      name: 'Ошибка данных',
      icon: WarningAmberIcon,
      istyle: 'fill-red-500 w-6 sm:w-6 xl:w-8 2xl:w-8 h-6 sm:h-6 xl:h-7 2xl:h-7',
    },
  },
};

const DeviceStatuses = ({ device, style, icon = true }) => {
  return (
    <>
      {device?.last_event?.statuses.map((status, i) => {
        const Icon = () => React.createElement(devStatuses[device.type.key][status]?.icon, { key: i, class: devStatuses[device.type.key][status]?.istyle });
        return (
          <>
            <p class={style}>
              <>
                <div class="flex items-center justify-start">
                  {icon && (
                    <div class="mr-2">
                      <Icon></Icon>
                    </div>
                  )}
                  <p class={style}>{devStatuses[device.type.key][status]?.name}</p>
                </div>
              </>
            </p>
          </>
        );
      })}
    </>
  );
};

export default DeviceStatuses;
