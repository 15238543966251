import React, { useState } from 'react';
import DeviceIcons from '../deviceList/DeviceIcons';
import LastEventInfo from './LastEventInfo';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import Modal from '../components/Modal';
import Chartjs from '../components/Chartjs';

const Device = ({ device }) => {
  const [showModal, setShowModal] = useState(false);

  const { type, serial_number, last_event } = device;
  const { sub_type } = type;

  return (
    <div className="bg-white first:rounded-tl-3xl cursor-pointer rounded-3xl last:rounded-br-3xl p-5 border-l select-none tap-highlight-transparent flex flex-col justify-between h-full">
      <div className="grid grid-cols-6 items-center">
        <DeviceIcons device={device} />
        <div className="col-span-5 ml-4">
          <p className="truncate">{type?.name}</p>
          <p className="truncate text-sm text-gray-400">{serial_number}</p>
        </div>
      </div>

      <div className="mt-2 flex-1">
        <p className="truncate text-xs text-gray-400">
          <LastEventInfo device={device} />
        </p>
      </div>

      {!isNaN(new Date(last_event?.at)) && (
        <>
          <div className="grid grid-cols-2">
            <div>
              <p className="h-6 mt-2">Датчик</p>
              <p className="text-gray-800 col-span-4 mr-3 justify-self-end text-xl sm:text-xl md:text-xl lg:text-xl xl:text-2xl 2xl:text-2xl">
                {sub_type?.key === 'sensor:water:leakage' && (
                  <p className="text-gray-800 col-span-4 mr-3 justify-self-end text-xl sm:text-xl md:text-xl lg:text-xl xl:text-2xl 2xl:text-2xl">
                    {last_event?.value ? 'Открыт' : 'Закрыт'}
                  </p>
                )}
                {sub_type?.key === 'sensor:temperature' && (
                  <p className="text-gray-800 col-span-4 mr-3 justify-self-end text-xl sm:text-xl md:text-xl lg:text-xl xl:text-2xl 2xl:text-2xl">
                    {(last_event?.value ? last_event?.value : '-') + ' °C'}
                  </p>
                )}
              </p>
            </div>
            <div className="flex items-center justify-end">
              <div
                onClick={() => setShowModal(true)}
                className="flex items-center justify-center bg-gray-100 hover:bg-gray-200 rounded-full p-2"
                style={{ width: 'fit-content' }}
                id="icon-chart"
              >
                <QueryStatsIcon className="text-orange-500 w-6 sm:w-6 xl:w-8 2xl:w-8 h-6 sm:h-6 xl:h-7 2xl:h-7" />
              </div>
            </div>
          </div>
        </>
      )}

      <div id="modal-chart">
        <Modal showModal={showModal} setShowModal={setShowModal} title="История данных устройства">
          <Chartjs device={device} />
        </Modal>
      </div>
    </div>
  );
};

export default Device;
