import * as React from 'react';
import { verify } from '../api/api';
import { useNavigate, useSearchParams } from 'react-router-dom';
import HourglassTopRoundedIcon from '@mui/icons-material/HourglassTopRounded';
import BtnFilled from '../components/BtnOutlined';

import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';

const Verify = ({ userLoggedIn }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [res, setRes] = React.useState({});
  const [progress, setProgress] = React.useState(true);

  React.useEffect(() => {
    const ticket = searchParams.get('ticket');
    verify(ticket, setRes, setProgress);
  }, []);

  const goTo = async () => {
    navigate('/');
  };

  return (
    <div class="flex w-full min-h-screen p-4 items-start justify-center bg-gradient-to-r from-[#ededed] to-[#dcdcdc]">
      <div class="w-full max-w-lg">
        <form class="bg-white shadow-2xl rounded-3xl border-b border border-gray-200 px-8 pt-6 pb-8 mb-4">
          {progress ? (
            <>
              <div class="flex justify-center mb-4">
                <HourglassTopRoundedIcon class="fill-orange-500 animate-spin w-8 h-8"></HourglassTopRoundedIcon>
              </div>
            </>
          ) : (
            <>
              {res.status === 'success' ? (
                <div class="flex mb-4">
                  <CheckCircleOutlineRoundedIcon class="fill-green-500 w-8 h-8"></CheckCircleOutlineRoundedIcon>
                  <p class="text-gray-800 ml-4 mt-0.5 text-lg font-bold truncate">Регистрация успешно подтверждена</p>
                </div>
              ) : (
                <div class="flex mb-4">
                  <CheckCircleOutlineRoundedIcon class="fill-red-500 w-8 h-8"></CheckCircleOutlineRoundedIcon>
                  {/*{res.error}*/}
                  <p class="text-gray-800 ml-4 mt-0.5 text-lg font-bold truncate">Ошибка подтверждения регистрации</p>
                </div>
              )}
            </>
          )}
          <div class="mb-4">
            <BtnFilled title={userLoggedIn ? 'Перейти в личный кабинет' : 'Войти'} btnCb={() => goTo()}></BtnFilled>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Verify;
