import React from 'react';
import DeviceIcons from '../deviceList/DeviceIcons';
import LastEventInfo from './LastEventInfo';
import DeviceStatuses from '../deviceList/DeviceStatuses';

const Device = ({ device }) => {

  return (
    <div className="bg-white first:rounded-tl-3xl cursor-pointer rounded-3xl last:rounded-br-3xl p-5 border-l select-none tap-highlight-transparent flex flex-col justify-between h-full">
      <div className="grid grid-cols-6 items-center">
        <DeviceIcons device={device} />
        <div className="col-span-5 ml-4">
          <p className="truncate">{device?.type?.name}</p>
          <p className="truncate text-sm text-gray-400">{device?.serial_number}</p>
        </div>
      </div>
      <div className="mt-2 flex-1">
        <p className="truncate text-xs text-gray-400">
          <LastEventInfo device={device} />
        </p>
      </div>
      <DeviceStatuses device={device} style="text-gray-800 col-span-4 mr-3 justify-self-end text-xl sm:text-xl md:text-xl lg:text-xl xl:text-2xl 2xl:text-2xl" icon={false} />
    </div>
  );
};

export default Device;
