import React from 'react';
import BtnText from '../components/BtnText';
import BtnOutlined from '../components/BtnOutlined';

import LoadingSpinner from '../components/LoadingSpinner';
import AlertError from '../components/AlertError';

import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

const styles = ['relative  flex-auto', 'relative flex-auto'];

const Modal = ({
  children,
  showModal,
  setShowModal,
  saveCb,
  closeCb,
  title,
  error,
  style = 0,
  loading = false,
  titleOn = 'Сохранить',
  showSave = true,
  classWidth = 'relative w-11/12 lg:w-1/2 my-6 mx-3',
}) => {
  const [stl, setStyle] = React.useState('');
  React.useEffect(() => {
    switch (style) {
      case 0:
        setStyle(styles[0]);
        break;
      case 1:
        setStyle(styles[1]);
        break;
      default:
        setStyle(styles[0]);
    }
  }, []);

  const save = () => {
    if (saveCb) saveCb();
  };

  const close = () => {
    setShowModal(false);
    if (closeCb) closeCb();
  };
  return (
    <>
      {showModal ? (
        <>
          <>
            <div className="justify-center items-start flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
              <div className={classWidth}>
                {/*content*/}
                <div className="border-0 rounded-3xl p-3 sm:p-3 md:p-3 xl:p-5 2xl:p-5 shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  {/*header*/}
                  <div className="flex items-center justify-between pb-3 sm:pb-3 md:pb-3 xl:pb-3 2xl:pb-3 border-solid border-blueGray-200 rounded-t">
                    <div className="text-start text-md sm:text-md md:text-xl xl:text-2xl 2xl:text-2xl">{title}</div>
                    <CloseOutlinedIcon
                      class="w-6 sm:w-6 xl:w-8 2xl:w-8 h-6 sm:h-6 xl:h-7 2xl:h-7 fill-black hover:bg-orange-50 hover:fill-orange-500"
                      onClick={() => close()}></CloseOutlinedIcon>
                  </div>
                  {/*body*/}
                  <div className={stl}>
                    <div>{children}</div>
                  </div>
                  {/*footer*/}
                  <div className="flex items-center justify-end pt-1 sm:pt-2 lg:pt-5 xl:pt-6 2xl:pt-6 border-solid border-blueGray-200 rounded-b">
                    {loading ? (
                      <div className="truncate w-full">
                        <LoadingSpinner error={error} loading={loading}></LoadingSpinner>
                      </div>
                    ) : (
                      <div className="truncate w-full">
                        <AlertError error={error}></AlertError>
                      </div>
                    )}

                    {/*
                  <div className="w-36">
                    <BtnText title="Закрыть" btnCb={() => close()}></BtnText>
                  </div>*/}

                    {saveCb && showSave && (
                      <div className="w-32 ml-4">
                        <BtnOutlined title={titleOn} btnCb={() => save()}></BtnOutlined>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        </>
      ) : null}
    </>
  );
};

export default Modal;
