import * as React from 'react';
import { signUp, getUserApi, signUpApi } from '../api/api';
import { saveUser } from '../api/api2';
import { useNavigate } from 'react-router-dom';
import BtnFilled from '../components/BtnFilled';
import BtnOutlined from '../components/BtnOutlined';
import Input from '../components/Input';
import InputPasswd from '../components/InputPasswd';
import Modal from '../components/Modal';
import HtmlViewer from '../components/HtmlViewer';

import PersonalData from '../components/PersonalData';
import TermsOfUse from '../components/TermsOfUse';

import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const Registration = ({ setUser, setAuthorized }) => {
  const navigate = useNavigate();
  const [_user, _setUser] = React.useState({ user: { role: 'customer' }, redirect_link: process.env.REACT_APP_REDIRECT_LINK + 'verify' });
  const [_res, _setRes] = React.useState({});

  const [showModalPersonalData, setShowModalPersonalData] = React.useState(false);
  const [showModalTermsOfUse, setShowModalTermsOfUse] = React.useState(false);

  const [decl, setDecl] = React.useState({
    personalData: false,
    termsOfUse: false,
  });

  async function save() {
    var user = _user.user;
    user.username = _user.user.email;
    await saveUser(
      user,
      function (data) {},
      function (err) {}
    );
  }

  const register = async () => {
    await signUpApi(
      _user,
      async function (data) {
        await save();
      },
      function (err) {
        _setRes(err);
      }
    );

    await getUserApi(
      function (data) {
        setUser(data);
        setAuthorized(true);
        navigate('/user');
      },
      function (err) {
        setAuthorized(false);
      }
    );
  };

  return (
    <div class="w-full max-w-lg">
      <form class="bg-white shadow-2xl rounded-b-3xl border-b border-x border-gray-300 px-8 pt-6 pb-8 mb-4">
        <div class="mb-4">
          <Input
            label="Фамилия Имя и Отчество"
            value={_user?.user?.fio}
            inputCb={(e) => _setUser({ ..._user, user: { ..._user.user, fio: e.target.value } })}
            id="fio"
            type="text"
            placeholder={'Иванов Иван Иванович'}></Input>
        </div>

        <div class="mb-4">
          <Input
            label="Номер Телефона"
            value={_user?.user?.phone}
            inputCb={(e) => _setUser({ ..._user, user: { ..._user.user, phone: e.target.value } })}
            id="phone"
            type="text"
            placeholder={'+7 945 564 923 56'}></Input>
        </div>

        <div class="mb-4">
          <Input
            label="Email"
            value={_user?.user?.email}
            inputCb={(e) => _setUser({ ..._user, user: { ..._user.user, email: e.target.value } })}
            id="email"
            type="text"
            placeholder={'someone@example.com'}></Input>
        </div>

        <div class="mb-4">
          <InputPasswd
            label="Пароль"
            value={_user?.user?.password}
            inputCb={(e) => _setUser({ ..._user, user: { ..._user.user, password: e.target.value } })}
            id="passwordreg"></InputPasswd>
        </div>

        <div class="grid grid-cols-12 gap-4 items-center justify-items-center mb-2">
          <div class="col-span-1">
            <CheckCircleIcon
              class={
                decl.personalData
                  ? 'w-6 sm:w-6 xl:w-8 2xl:w-8 h-6 sm:h-6 xl:h-8 2xl:h-8 fill-orange-500'
                  : 'w-6 sm:w-6 xl:w-8 2xl:w-8 h-6 sm:h-6 xl:h-8 2xl:h-8 fill-gray-500'
              }></CheckCircleIcon>
          </div>
          <div class="col-span-8 justify-self-start">
            <p class="select-none text-wrap text-xs sm:text-base xl:text-base 2xl:text-base">Обработка персональных данных</p>
          </div>
          <div class="col-span-3 justify-self-end">
            <BtnOutlined title="Читать" btnCb={() => setShowModalPersonalData(true)}></BtnOutlined>
          </div>
        </div>

        <div class="grid grid-cols-12 gap-4 items-center justify-items-center mb-2">
          <div class="col-span-1">
            <CheckCircleIcon
              class={
                decl.termsOfUse
                  ? 'w-6 sm:w-6 xl:w-8 2xl:w-8 h-6 sm:h-6 xl:h-8 2xl:h-8 fill-orange-500'
                  : 'w-6 sm:w-6 xl:w-8 2xl:w-8 h-6 sm:h-6 xl:h-8 2xl:h-8 fill-gray-500'
              }></CheckCircleIcon>
          </div>
          <div class="col-span-8 justify-self-start">
            <p class="select-none text-wrap text-xs sm:text-base xl:text-base 2xl:text-base">Пользовательское соглашение</p>
          </div>
          <div class="col-span-3 justify-self-end">
            <BtnOutlined title="Читать" btnCb={() => setShowModalTermsOfUse(true)}></BtnOutlined>
          </div>
        </div>

        {_res && <p class="mb-4 text-red-500 p-0 font-bold text-xs italic">{_res.error}</p>}

        {decl.personalData && decl.termsOfUse && <BtnFilled title="Зарегистрироваться" btnCb={register}></BtnFilled>}
      </form>
      <Modal
        showModal={showModalPersonalData}
        setShowModal={setShowModalPersonalData}
        closeCb={() => setDecl({ ...decl, personalData: true })}
        title="Политика в отношении обработки персональных данных"
        showSave={false}
        error={null}
        classWidth="relative w-11/12 lg:w-1/2 my-6 mx-2">
        <PersonalData></PersonalData>
      </Modal>
      <Modal
        showModal={showModalTermsOfUse}
        setShowModal={setShowModalTermsOfUse}
        closeCb={() => setDecl({ ...decl, termsOfUse: true })}
        title="Пользовательское соглашение"
        showSave={false}
        error={null}
        classWidth="relative w-11/12 lg:w-1/2 my-6 mx-2">
        <TermsOfUse></TermsOfUse>
      </Modal>
    </div>
  );
};

export default Registration;
