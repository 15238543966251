import { useState } from 'react';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';

const Accordion = ({ id, size, Icon, name, children }) => {
  const [accordion, setAccordion] = useState({});

  const handleClick = () => {
    setAccordion(prevAccordion => ({
      ...prevAccordion,
      [id]: !prevAccordion[id]
    }));
  };

  return (
    <>
      <div
        onClick={handleClick}
        className={`px-1 py-4 sm:px-2 xl:px-4 2xl:px-4 bg-white w-full flex border-t justify-between items-center select-none ${id === 0 ? 'rounded-t-3xl' : ''} ${id === size - 1 ? 'rounded-b-3xl' : ''}`}
      >
        <div className="flex items-center px-1.5 gap-2">
          {/*{Icon && <Icon className="fill-gray-800 w-6 sm:w-6 xl:w-8 2xl:w-8 h-6 sm:h-6 xl:h-7 2xl:h-7"></Icon>}*/}
          <p className="truncate text-2xl font-normal">{name}</p>
        </div>
        {!accordion[id] ? (
          <KeyboardArrowUp className="fill-gray-800 mx-1.5 w-6 sm:w-6 xl:w-8 2xl:w-8 h-6 sm:h-6 xl:h-7 2xl:h-7" />
        ) : (
          <KeyboardArrowDown className="fill-gray-800 mx-1.5 w-6 sm:w-6 xl:w-8 2xl:w-8 h-6 sm:h-6 xl:h-7 2xl:h-7" />
        )}
      </div>
      {!accordion[id] && <div className="px-2">{children}</div>}
    </>
  );
};

export default Accordion;
