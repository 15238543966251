import React from 'react';

// This is a dynamic toggle switch component
const ToggleSwitch = ({ buttons = [{ id: 1 }, { id: 2 }, { id: 3 }], activeIndex }) => {
  // Styles for the buttons
  const buttonStyle = 'w-2 h-2 m-1 rounded-full shadow-md';
  const activeButtonStyle = 'bg-orange-500 ' + buttonStyle;
  const inactiveButtonStyle = 'bg-white ' + buttonStyle;

  return (
    <div className="flex items-center justify-center p-1">
      <div className="relative w-12 h-4 bg-gray-200 rounded-full">
        <div className="flex justify-between items-center absolute inset-0 m-auto">
          {buttons.map((button, index) => (
            <button key={index} className={activeIndex === index ? activeButtonStyle : inactiveButtonStyle}></button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ToggleSwitch;

