import React from 'react';

const BtnOutlined = ({ title, btnCb, disabled = false, type = 'button' }) => {
  return (
    <button
      disabled={disabled}
      onClick={() => btnCb()}
      type={type}
      className="bg-transparent w-full truncate border border-gray-800 hover:bg-orange-50 text-black font-semibold hover:text-orange-500 hover:border-orange-500 rounded-full py-2 px-4">
      {title}
    </button>
  );
};

export default BtnOutlined;
