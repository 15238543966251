import React from 'react';

const Declaration = ({}) => {
  return (
    <div>
      <p class="font-bold text-center pb-4">1. ОБЩИЕ ПОЛОЖЕНИЯ</p>
      <p class="pb-2 indent-0">
        1.1. Настоящее Пользовательское соглашение (далее – Соглашение) относится к следующим взаимосвязанным сайтам и приложениям (далее - Программы):
      </p>
      <ul class="list-disc pl-10 pb-2">
        <li>
          <a href="https://lk.meridian.pro" target="_blank" rel="noopener noreferrer">
            сайт lk.meridian.pro
          </a>
        </li>
        <li>
          <a href="https://lk2.meridian.pro" target="_blank" rel="noopener noreferrer">
            сайт lk2.meridian.pro
          </a>
        </li>
        <li>
          <a href="https://service.meridian.pro" target="_blank" rel="noopener noreferrer">
            сайт service.meridian.pro
          </a>
        </li>
        <li>
          <a href="https://itunes.apple.com/app/mira/id1234567890" target="_blank" rel="noopener noreferrer">
            мобильное приложение MIRA для iOS
          </a>
        </li>
        <li>
          <a href="https://play.google.com/store/apps/details?id=com.mira.android" target="_blank" rel="noopener noreferrer">
            мобильное приложение MIRA для Android
          </a>
        </li>
        <li>
          <a href="https://cloud.meridian.pro" target="_blank" rel="noopener noreferrer">
            облачный сервер ООО «МЕРИДИАН»
          </a>
        </li>
      </ul>
      <p class="pb-2 indent-0">
        1.2. Программы являются собственностью юридического лица ООО «МЕРИДИАН», ОГРН: 5087746158880, ИНН: 7734592378, юридический адрес: 119071, г. Москва,
        вн.тер.г. Муниципальный Округ Донской, ул Малая Калужская, д. 15, стр. 17 (далее - Компания).
      </p>
      <p class="pb-2 indent-0">1.3. Настоящее Соглашение регулирует отношения между Компанией и Пользователем его Программ.</p>
      <p class="pb-2 indent-0">
        1.4. Компания оставляет за собой право в любое время изменять, добавлять или удалять пункты настоящего Соглашения с уведомлением Пользователя через
        интерфейс Программ. Пользователь несет персональную ответственность за ознакомление с текстом настоящего Соглашения и последующих изменений в нём.
      </p>
      <p class="pb-2 indent-0">
        1.5. Регистрация Пользователем учетной записи для использования Программ означает принятие настоящего Соглашения. Доступ Пользователя в Программы
        осуществляется через Личный кабинет на основании логина и пароля.
      </p>
      <p class="pb-2 indent-0">
        1.6. При использовании Программ для взаимодействия с другими сервисами и/или программами Пользователь также всецело принимает и соглашается с условиями
        использования, лицензионными соглашениями и иными регулирующими документами соответствующих сервисов и/или программ, а также соглашается на передачу
        необходимых данных, требующихся для функционирования этих сервисов и/или программ.
      </p>
      <p class="pb-2 indent-0">
        1.7. Под действие настоящего Соглашения подпадают все существующие на данный момент услуги, сервисы, функции Программ, а также любые их последующие
        модификации и новые появляющиеся в дальнейшем.
      </p>
      <p class="pb-2 indent-0">
        1.8. Действие настоящего соглашения бессрочное и может быть прекращено по инициативе Пользователя путем удаления Личного кабинета направив обращение в
        техническую поддержку Компании через форму обратной связи в Личном кабинете.
      </p>
      <p class="pb-2 indent-0">
        1.9. Компания может заблокировать доступ к Программам или её определенным функциям в случае нарушения Пользователем условий настоящего Соглашения или по
        требованию компетентных органов РФ.
      </p>
      <p class="pb-2 indent-0">1.10. Компания имеет право раскрыть имеющуюся в её распоряжении информацию о Пользователе третьим лицам, необходимую для:</p>
      <ul class="list-disc pl-10 pb-2">
        <li>выполнения положений действующего законодательства или судебных решений;</li>
        <li>обеспечения выполнения условий настоящего Соглашения;</li>
        <li>обеспечения функционирования Программ или каких-либо её функций;</li>
        <li>защиты прав или безопасности других Пользователей.</li>
      </ul>
      <p class="font-bold text-center py-4">2. СТОИМОСТЬ ИСПОЛЬЗОВАНИЯ</p>
      <p class="pb-2 indent-0">
        2.1. Доступ к Программам предоставляется Компанией по модели Freemium: базовые функции Программ предоставляются бесплатно, расширенные платно.
        Актуальные цены и тарифы публикуются в Личном кабинете.
      </p>
      <p class="pb-2 indent-0">
        2.2. Компания определяет стоимость Программ путем добавления, удаления и изменения Тарифных планов, которые определяют: список бесплатных функций,
        список платных функций, цены на платные функции. Список платных и бесплатных функций закрепляется за Пользователем в момент подключения им конкретного
        Тарифного плана, который может быть изменен только самим Пользователем. Компания вправе менять цены на платные функции в существующих Тарифных планах
        без предварительного согласования с Пользователем, но не чаще чем один раз в календарный год.
      </p>
      <p class="pb-2 indent-0">
        2.3. Компания вправе добавлять новые Тарифные планы без предварительного согласования с Пользователем. Добавление новых тарифных планов не влечет
        автоматического изменения Тарифного плана для Пользователя, пока он лично не изъявит такого желания.
      </p>
      <p class="pb-2 indent-0">2.4. Оплата Пользователем осуществляется по авансовой схеме путем внесения денежных средств на баланс в Личном кабинете.</p>
      <p class="pb-2 indent-0">
        2.5. При отрицательном балансе Компания блокирует доступ к Программам или каким-то выборочным функциям в соответствии с активным Тарифным планом
        Пользователя.
      </p>
      <p class="font-bold text-center py-4">3. ОТВЕТСТВЕННОСТЬ КОМПАНИИ</p>
      <p class="pb-2 indent-0">
        3.1. Система предоставляется на условиях «как есть» (as is). Компания не предоставляет никаких гарантий в отношении безошибочной и бесперебойной работы
        Программ или отдельных компонентов и/или функций, соответствия Программ конкретным целям и ожиданиям Пользователя, а также не предоставляет никаких иных
        гарантий, прямо не указанных в настоящем Соглашении.
      </p>
      <p class="pb-2 indent-0">
        3.2. Компания не несет ответственности за какие-либо прямые или косвенные последствия какого-либо использования или невозможности использования Программ
        и/или ущерб, причиненный Пользователю и/или третьим сторонам в результате какого-либо использования, неиспользования или невозможности использования
        Программ или отдельных компонентов и/или функций, в том числе из-за возможных ошибок или сбоев в работе Программ.
      </p>
      <p class="pb-2 indent-0">
        3.3. Пользователь настоящим уведомлен и соглашается, что при использовании Программ в автоматическом режиме Компании передается следующая информация:
        тип операционной системы и модель устройства Пользователя, версия и идентификатор программ и устройств SAURES, сетевые параметры подключения к
        Программам, статистика использования функций Программ.
      </p>
      <p class="pb-2 indent-0">
        3.4. Компания обеспечивает функционирование Программ на серверном оборудовании, установленном в ЦОД на территории РФ и предоставляет Пользователю
        круглосуточный доступ к Программам, за исключением времени проведения профилактических работ.
      </p>
      <p class="pb-2 indent-0">
        3.5. Вопросы и претензии, связанные с использованием или невозможностью использования Программ, а также возможным нарушением законодательства или прав
        третьих лиц, должны направляться в техническую поддержку Компании через форму обратной связи в Личном кабинете. Техническая поддержка Пользователей
        осуществляется на условиях, изложенных в форме обратной связи.
      </p>
      <p class="pb-2 indent-0">
        3.6. Компания не несет ответственности за работоспособность систем денежных переводов, банков, платежных систем и за задержки, связанные с их работой.
      </p>
      <p class="pb-2 indent-0">3.7. Компания праве расширять, дорабатывать, обновлять функциональность Программ без получения согласия Пользователя.</p>
      <p class="pb-2 indent-0">
        3.8. Если Пользователь не пользуется Личным кабинетом или данные не обновляются более 3 лет, то Компания праве удалить учетную запись и данные
        Пользователя с уведомлением по электронной почте, если таковая указана в Личном кабинете.
      </p>
      <p class="font-bold text-center py-4">4. ПРАВА И ОБЯЗАННОСТИ ПОЛЬЗОВАТЕЛЯ</p>
      <p class="pb-2 indent-0">4.1. Пользователь вправе:</p>
      <ul class="list-disc pl-10 pb-2">
        <li>Пользоваться всеми имеющимися в Программах функциями на условиях и в соответствии с выбранным Тарифным планом;</li>
        <li>
          Задавать любые вопросы, относящиеся к Программам через форму обратной связи в Личном кабинете, через звонок по номеру телефона указанному в личном
          кабинете или через электронную почту компании info@meridian.pro;
        </li>
        <li>Пользоваться Программами исключительно в целях и порядке, предусмотренных Соглашением и не запрещенных законодательством Российской Федерации;</li>
        <li>Копировать информацию и данные из Личного кабинета;</li>
        <li>Использовать информацию и данные из Личного кабинета в личных и коммерческих целях.</li>
      </ul>
      <p class="pb-2 indent-0">4.2. Пользователь не имеет права:</p>
      <ul class="list-disc pl-10 pb-2">
        <li>Выполнять действия, которые могут рассматриваться как нарушающие стабильную работу Программ или мешающие другим Пользователям.</li>
        <li>
          Использовать Программы для распространения рекламы и совершения противозаконных действий, включая, но не ограничиваясь: спам-рассылки, направления
          угроз и оскорблений, призыва к противоправным действиям.
        </li>
        <li>Получать доступ к исходному код Программ, редактировать или изучать исходный код Программ, вскрывать технологию работы Программ.</li>
        <li>Копировать, воспроизводить Программы или отдельные ее элементы и сохранять Программы на материальном носителе или на облачных хранилищах.</li>
        <li>Выполнять действия для обхода технические ограничения, установленные в Программах.</li>
        <li>Использовать Программы и их содержание в любых целях, запрещенных законодательством Российской Федерации.</li>
      </ul>
      <p class="pb-2 indent-0">4.3. Пользователь обязуется:</p>
      <ul class="list-disc pl-10 pb-2">
        <li>
          При использовании платных функций самостоятельно следить за балансом в Личном кабинете и нести ответственность за последствия, возникшие при его
          переходе в отрицательное состояние.
        </li>
        <li>Предоставлять по запросу Компании дополнительную информацию, которая имеет непосредственное отношение к используемым Программам.</li>
        <li>Соблюдать права авторов и иных правообладателей при использовании Программ.</li>
        <li>
          Нести персональную ответственность за сохранение конфиденциальности информации учётной записи, включая пароль, а также за всю деятельность, которая
          ведётся от имени Пользователя учётной записи.
        </li>
        <li>Незамедлительно уведомить Компанию о несанкционированном использовании его Личного кабинета или любом другом нарушении системы безопасности.</li>
      </ul>
      <p class="font-bold text-center py-4">5. РАЗРЕШЕНИЕ СПОРОВ</p>
      <p class="pb-2 indent-0">
        5.1. В случае возникновения любых разногласий или споров между Компанией и Пользователем (далее Стороны) обязательным условием до обращения в суд
        является предъявление претензии - письменного предложения о добровольном урегулировании спора.
      </p>
      <p class="pb-2 indent-0">
        5.2. Сторона, получившая претензию в течение 30 календарных дней со дня ее получения, письменно уведомляет заявителя претензии о результатах
        рассмотрения претензии.
      </p>
      <p class="pb-2 indent-0">
        5.3. При невозможности разрешить спор в добровольном порядке любая из Сторона вправе обратиться в суд за защитой своих прав, которые предоставлены им
        действующим законодательством Российской Федерации.
      </p>
    </div>
  );
};

export default Declaration;
