import React from 'react';

import Input from '../components/Input';
import Dropdown from '../components/Dropdown';

const DeviceEditor = ({ setDevice, device, innitialDevs, objects, objectId, setObjectId }) => {
  const [index, setIndex] = React.useState({
    type: 0,
    subtype: 0,
    output: 0,
  });

  const onTypeChange = (i) => {
    setIndex({ ...index, type: i, subtype: 0, output: 0 });
    setDevice({
      ...device,
      device_type: innitialDevs[i].device_type,
      device_sub_type: innitialDevs[i].sub[0].device_type,
      output_type: innitialDevs[i].sub[0].output_type[0].key,
    });
  };

  const onSubTypeChange = (i) => {
    setIndex({ ...index, subtype: i, output: 0 });
    setDevice({
      ...device,
      device_sub_type: innitialDevs[index.type].sub[i].device_type,
      output_type: innitialDevs[index.type].sub[i].output_type[0].key,
    });
  };

  const onInputChange = (i) => {
    setIndex({ ...index, output: i });
    setDevice({
      ...device,
      output_type: innitialDevs[index.type].sub[index.subtype].output_type[i].key,
    });
  };

  const bind = () => {};

  return (
    <>
      <div class="w-full">
        <Dropdown
          label={'Тип устройств'}
          options={innitialDevs}
          value={index.type}
          inputCb={(e) => onTypeChange(parseInt(e.target.value))}
          id="type"
          valkey="device_type_i"
          optkey="category"></Dropdown>
      </div>
      <div class="w-full mt-4">
        <Dropdown
          label={'Подтип устройств'}
          options={innitialDevs[index.type].sub}
          value={index.subtype}
          inputCb={(e) => onSubTypeChange(parseInt(e.target.value))}
          id="type"
          valkey="device_subtype_i"
          optkey="category"></Dropdown>
      </div>

      {(device.device_type === 'counter' || device.device_type === 'sensor') && (
        <div class="w-full  mt-4">
          <Dropdown
            label={'Тип выхода'}
            options={innitialDevs[index.type].sub[index.subtype].output_type}
            value={index.output}
            inputCb={(e) => onInputChange(parseInt(e.target.value))}
            id="type"
            valkey="i"
            optkey="name"></Dropdown>
        </div>
      )}

      <div class="w-full  mt-4">
        <Input
          value={device.serial_number}
          inputCb={(e) => setDevice({ ...device, serial_number: e.target.value })}
          label="Серийный номер"
          id="type"
          type="text"
          placeholder={'A123'}></Input>
      </div>
      <div class="w-full  mt-4">
        <Input
          value={device.manufacturer}
          inputCb={(e) => setDevice({ ...device, manufacturer: e.target.value })}
          label="Производитель"
          id="type"
          type="text"
          placeholder={'Меридиан'}></Input>
      </div>

      <div class="w-full mt-4">
        <Dropdown
          label={'Привязать к объекту'}
          options={objects}
          inputCb={(e) => setObjectId({ ...objectId, object_id: parseInt(e.target.value) })}
          id="devs"
          valkey="id"
          optkey="address"></Dropdown>
      </div>
    </>
  );
};

export default DeviceEditor;
