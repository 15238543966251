import * as React from 'react';
import { getUserApi, resetPassRqApi, logInApi } from '../api/api';
import { saveUser } from '../api/api2';
import { useNavigate, useLocation } from 'react-router-dom';
import BtnFilled from '../components/BtnFilled';
import Input from '../components/Input';
import InputPasswd from '../components/InputPasswd';
import Modal from '../components/Modal';
import AlertAccent from '../components/AlertAccent';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoadingSpinner from '../components/LoadingSpinner';

const Login = ({ setUser, setAuthorized }) => {
  const [showAddModal, setShowAddModal] = React.useState(false);

  const [refreshing, setRefreshing] = React.useState(false);

  var testLogIn =
    process.env.REACT_APP_MODE === 'dev'
      ? {
          username: 'rise33@yandex.ru',
          password: '$outh3*3Cross',
        }
      : {};

  const navigate = useNavigate();
  //username: 'flassie6',
  //password: '123',
  const [_user, _setUser] = React.useState(testLogIn);
  const [_error, _setError] = React.useState({});

  const login = async () => {
    setRefreshing(true);
    await logInApi(
      _user,
      async function (data) {
        await save();
      },
      function (err) {
        setRefreshing(false);
        toast.error(err.error ? err.error : 'Ошибка сервера 1', { hideProgressBar: false });
      }
    );

    await getUserApi(
      function (data) {
        setUser(data);
        setAuthorized(true);
      },
      function (err) {
        setAuthorized(false);
      }
    );
  };

  async function save() {
    await saveUser(
      _user,
      function (data) {
        setRefreshing(false);
      },
      function (err) {
        setRefreshing(false);
        toast.error(err.error ? err.error : 'Ошибка сервера 2', { hideProgressBar: false });
      }
    );
  }

  const [resPass, setResPass] = React.useState({ redirect_link: process.env.REACT_APP_REDIRECT_LINK + 'resetpass', email: _user.username });
  const [resErr, setResErr] = React.useState([]);
  const [resOk, setResOk] = React.useState(false);
  const reset = async () => {
    setResErr([]);
    await resetPassRqApi(
      resPass,
      function (data) {
        setResOk(true);
      },
      function (err) {
        toast.error(err.error ? err.error : 'Ошибка сервера', { hideProgressBar: false });
        setResErr((p) => [...p, err.error ? err : { error: 'Ошибка сервера' }]);
      }
    );
  };

  const close = async () => {
    setResErr([]);
    toast.dismiss();
  };

  return (
    <>
      <>
        <div class="w-full max-w-lg">
          <form class="bg-white shadow-2xl rounded-b-3xl border-b border-x border-gray-300 px-8 pt-6 pb-8 mb-4">
            <div class="mb-4">
              <Input
                label="Логин"
                value={_user.username}
                inputCb={(e) => _setUser({ ..._user, username: e.target.value })}
                id="username"
                type="text"
                autocomplete="username"
                placeholder={'someone@example.com'}></Input>
            </div>
            <div class="mb-4">
              <InputPasswd
                label="Пароль"
                value={_user.password}
                inputCb={(e) => _setUser({ ..._user, password: e.target.value })}
                id="password"
                autocomplete="current-password"></InputPasswd>
            </div>

            {_error && <p class="mb-4 text-red-500 p-0 font-bold text-xs italic">{_error.error}</p>}
            <div class="mb-4">
              <BtnFilled
                title={
                  refreshing ? (
                    <div
                      className="inline-block h-6 w-6 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                      role="status"></div>
                  ) : (
                    'Войти'
                  )
                }
                btnCb={login}></BtnFilled>
            </div>

            <BtnFilled title="Сбросить пароль" btnCb={() => setShowAddModal(true)}></BtnFilled>
          </form>
          <Modal
            showModal={showAddModal}
            setShowModal={setShowAddModal}
            titleOn="Сбросить"
            saveCb={() => reset()}
            showSave={!resOk}
            closeCb={() => close()}
            title="Сброс пароля"
            error={resErr}>
            <div class="mb-4">
              <Input
                label="Для сброса пароля введите ваш логин"
                value={resPass.email}
                inputCb={(e) => setResPass({ ...resPass, email: e.target.value })}
                id="login"
                type="text"
                placeholder={'someone@example.com'}></Input>
            </div>
            {resOk && <AlertAccent title="" text="На вашу почту была отправлена ссылка. Для сброса пароля перейдите по ней" showAlert={true}></AlertAccent>}
          </Modal>
        </div>
        <div>
          <ToastContainer />
        </div>
      </>
    </>
  );
};

export default Login;
