import React from 'react';

const Dropdown = ({ label, options, value, inputCb, arrayElementCb, id, valkey, optkey, optkey2 = undefined, disabled = false, emptyoption }) => {
  const handleChange = (e) => {
    const selectedValue = e.target.value;
    const selectedOption = options.find((opt) => opt[valkey] === Number(selectedValue));
    if (inputCb) inputCb(e);
    if (arrayElementCb) arrayElementCb(selectedOption);
  };

  const getNestedValue = (obj, key) => {
    return key.split('.').reduce((acc, part) => acc && acc[part], obj);
  };

  return (
    <>
      <label className={!disabled ? 'block text-gray-700 text-sm ml-2 mb-2' : 'block text-gray-400 text-sm ml-2 mb-2'} htmlFor={id}>
        {label}
      </label>

      <form className="flex flex-row h-10">
        <select
          disabled={disabled}
          value={value}
          onChange={handleChange}
          className={
            !disabled
              ? 'text-gray-800 bg-white leading-tight pl-4 h-10 border-gray-300 border-y border-l rounded-full rounded-r-none w-full py-1 px-2 outline-none'
              : 'text-gray-400 bg-white leading-tight pl-4 h-10 border-gray-100 border-y border-l rounded-full rounded-r-none w-full py-1 px-2 outline-none'
          }
          id={id}>
          <>
            {options.length === 0 && <option>{emptyoption}</option>}
            {options.map((opt, index) => {
              const optkey2Value = optkey2 ? getNestedValue(opt, optkey2) : '';
              return (
                <option key={index} value={opt[valkey]}>
                  {opt[optkey] + (optkey2Value ? ' ' + optkey2Value : '')}
                </option>
              );
            })}
          </>
        </select>
        <span
          className={
            !disabled
              ? 'flex items-center select-none bg-white rounded-full rounded-l-none border-gray-300 border-y border-r pr-5'
              : 'flex items-center select-none bg-white rounded-full rounded-l-none border-gray-100 border-y border-r pr-5'
          }></span>
      </form>
    </>
  );
};

export default Dropdown;
