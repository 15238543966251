//this is counter widget
import React from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Modal from '../components/Modal';

import DeviceNames from './DeviceNames';
import DeviceIcons from './DeviceIcons';
import DeviceValues from './DeviceValues';

import EditMenu from './EditMenu';

const Device = ({ device, user, getDevices, devices }) => {
  return (
    <div className="grid grid-cols-6 sm:grid-cols-12 h-16 items-center justify-center justify-items-center">
      <DeviceIcons device={device}></DeviceIcons>

      <div className="col-span-2 sm:col-span-8 justify-self-start overflow-hidden">
        <DeviceValues
          device={device}
          className="col-span-4"
          style="text-gray-800 text-lg sm:text-xl md:text-xl lg:text-xl xl:text-2xl 2xl:text-2xl"></DeviceValues>
        <DeviceNames className="col-span-2 sm:col-span-8 justify-self-start" device={device} style="text-sm line-clamp-1 text-gray-400"></DeviceNames>
      </div>

      <EditMenu device={device} user={user} getDevices={getDevices} devices={devices}></EditMenu>
    </div>
  );
};

export default Device;
