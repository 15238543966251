import React from 'react';
import BtnText from './BtnText';
import BtnOutlined from './BtnOutlined';

import AlertError from './AlertError';

import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { Delete } from '@mui/icons-material';

const Modal = ({ children, showAlert, setShowAlert, delCb, closeCb, title, error }) => {
  const del = () => {
    if (delCb) delCb();
  };

  const close = () => {
    setShowAlert(false);
    if (closeCb) closeCb();
  };
  return (
    <>
      {showAlert ? (
        <>
          <div className="justify-center items-start flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-11/12 lg:w-1/2 my-6 mx-3">
              {/*content*/}
              <div className="border-0 rounded-3xl shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-3 sm:p-3 md:p-3 xl:p-5 2xl:p-5 border-b border-solid border-blueGray-200 rounded-t">
                  <div className="text-md sm:text-md md:text-md xl:text-xl 2xl:text-xl font-semibold">{title}</div>
                  <CloseOutlinedIcon
                    class="w-6 sm:w-6 xl:w-8 2xl:w-8 h-6 sm:h-6 xl:h-7 2xl:h-7 fill-black hover:bg-orange-50 hover:fill-orange-500"
                    onClick={() => close()}></CloseOutlinedIcon>
                </div>
                {/*body*/}
                <div className="relative p-1 sm:p-2 lg:p-5 xl:p-6 2xl:p-6 flex-auto', 'relative flex-auto">
                  <div>{children}</div>
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-1 sm:p-2 lg:p-5 xl:p-6 2xl:p-6 border-t border-solid border-blueGray-200 rounded-b">
                  <div className="truncate w-full">
                    <AlertError error={error}></AlertError>
                  </div>

                  {/*
                  <div className="w-36">
                    <BtnText title="Закрыть" btnCb={() => close()}></BtnText>
                  </div>*/}

                  {delCb && (
                    <div className="w-32 ml-4">
                      <BtnOutlined title="Удалить" btnCb={() => del()}></BtnOutlined>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
};

export default Modal;
