import React from 'react';
import SpeedIcon from '@mui/icons-material/Speed';
import SensorIcon from '@mui/icons-material/SensorsRounded';
import DeveloperBoardIcon from '@mui/icons-material/DeveloperBoardOutlined';
import Accordion from '../components/Accordion';
import Counter from '../widgets/Counter';
import { getDevicesApi } from '../api/api';
import Controller from '../widgets/Controller';
import Sensor from '../widgets/Sensor';
import LoadingSpinner from '../components/LoadingSpinner';
import AlertAccent from '../components/AlertAccent';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const DeviceWidgets = {
  counter: Counter,
  controller: Controller,
  sensor: Sensor,
};

const Dashboard = ({ user }) => {
  const [loading, setLoading] = React.useState({ get: true, add: true, edit: true, delete: true });

  //devices - getting devices from backend
  const [devices, setDevices] = React.useState([]); //main variable
  const [devicesErr, setDevicesErr] = React.useState([]); //server respond variable
  const getDevices = async () => {
    setLoading({ ...loading, get: true });
    await getDevicesApi(
      function (data) {
        setLoading({ ...loading, get: false });
        setDevices(data);
      },
      function (err) {
        toast.error(err.error, { hideProgressBar: true });
        setLoading({ ...loading, get: false });
        setDevicesErr((p) => [...p, err]);
      }
    );
  };

  React.useEffect(() => {
    const get = async () => {
      await getDevices();
    };
    get();
  }, []);

  React.useEffect(() => {
    setTypes(createTypesArray(devices));
  }, [devices]);

  const [types, setTypes] = React.useState([]);

  const createTypesArray = (devices) => {
    const typesArr = devices.map((dev) => dev.description);
    return Array.from(new Set(typesArr));
  };

  return (
    <>
      <div>
        <ToastContainer />
      </div>
      <div className="sm:flex min-h-screen items-start justify-center bg-gradient-to-r p-2 sm:p-4 from-[#ededed] to-[#dcdcdc]">
        {!loading.get ? (
          <>
            {devices.length !== 0 ? (
              <div>
                <div className="my-4">
                  <p className="truncate text-3xl font-normal">Состояние устройств</p>
                </div>
                <div>
                  <>
                    {types.map((type, i) => {
                      return (
                        <div>
                          <p className="text-start text-2xl my-3">{type}</p>

                          <div className="grid grid-cols-1 gap-2 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-4 w-full">
                            {devices.map((device, index) => {
                              return (
                                typeof DeviceWidgets[device.type.key] !== 'undefined' &&
                                type === device.description &&
                                React.createElement(DeviceWidgets[device.type.key], {
                                  key: index,
                                  device: device,
                                })
                              );
                            })}
                          </div>
                        </div>
                      );
                    })}
                  </>
                </div>
              </div>
            ) : (
              <div className="bg-white shadow-2xl rounded-3xl px-6 pb-6 m-2">
                <div className="w-sm mt-6">
                  <AlertAccent
                    title="Устройства не добавленны!"
                    text="Для управления и отображения информации Вам необходимо добавить хотябы одно устройство"
                    showAlert={true}></AlertAccent>
                </div>
              </div>
            )}
          </>
        ) : (
          <LoadingSpinner error={devicesErr} loading={true}></LoadingSpinner>
        )}
      </div>
    </>
  );
};

export default Dashboard;
