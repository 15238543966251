import React from 'react';

import Input from '../components/Input';

const DeviceEditor = ({ setDeviceToEdit, deviceToEdit }) => {
  return (
    <>
      <div class="px-4 sm:px-4 lg:px-5 xl:px-8 2xl:px-10">
        <div class="w-full mt-4">
          <Input
            value={deviceToEdit.serial_number}
            inputCb={(e) => setDeviceToEdit({ ...deviceToEdit, serial_number: e.target.value })}
            label="Серийный номер"
            id="type"
            type="text"
            placeholder={'A123'}></Input>
        </div>
        <div class="w-full  my-4">
          <Input
            value={deviceToEdit.description}
            inputCb={(e) => setDeviceToEdit({ ...deviceToEdit, description: e.target.value })}
            label="Описание"
            id="descr"
            type="text"
            placeholder={'Счетчик в подвале'}></Input>
        </div>
        {/*
      <div class="w-full  mt-4">
        <Input
          value={deviceToEdit.clear_inputs}
          inputCb={(e) => setDeviceToEdit({ ...deviceToEdit, clear_inputs: parseInt(e.target.value) })}
          label="Описание"
          id="type"
          type="number"
          placeholder={'Свободные входы'}></Input>
      </div>*/}
      </div>
    </>
  );
};

export default DeviceEditor;
