import HourglassTopRoundedIcon from '@mui/icons-material/HourglassTopRounded';

import AlertError from './AlertError';

//{/*<HourglassTopRoundedIcon class="fill-orange-500 animate-spin w-8 h-8"></HourglassTopRoundedIcon>*/}

//flex justify-center

const LoadingSpinner = ({ error = [], loading = false, style = 'h-screen flex items-center justify-center' }) => {
  return (
    <>
      <div className={style}>
        {loading ? (
          <div
            className="inline-block h-6 w-6 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
            role="status">
            {/*<span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">Loading...</span>*/}
          </div>
        ) : (
          <AlertError error={error}></AlertError>
        )}
      </div>
    </>
  );
};

export default LoadingSpinner;
