import React, { useState } from 'react';
import { delDevIntegrationApi2 } from '../api/api2';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { editDeviceApi, setDevInputsApi, deleteDevApi } from '../api/api';
import Modal from '../components/Modal';
import ConfirmAlert from '../components/ConfirmAlert';
import AlertAccent from '../components/AlertErrorBox';
import AnyDeviceEditor from './AnyDeviceEditor';
import ControllerEditor from './ControllerEditor';
import DeviceCard from './DeviceCard';
import DeviceNames from './DeviceNames';
import { empty } from '../regex';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const innitialConfiguration = {
  intType: [
    { opt: 'Ежедневно', val: 'D' },
    { opt: 'Еженедельно', val: 'W' },
    { opt: 'Ежемесячно', val: 'M' },
  ],
  writingIntervals: [
    { opt: 'Каждый 1 час', val: 1 },
    { opt: 'Каждые 2 часа', val: 2 },
    { opt: 'Каждые 4 часа', val: 4 },
    { opt: 'Каждые 6 часов', val: 6 },
    { opt: 'Каждые 8 часов', val: 8 },
    { opt: 'Каждые 12 часов', val: 12 },
    { opt: 'Каждые 24 часов', val: 24 },
  ],
  weekDay: [
    { opt: 'Понедельник', val: 0 },
    { opt: 'Вторник', val: 1 },
    { opt: 'Среда', val: 2 },
    { opt: 'Четверг', val: 3 },
    { opt: 'Пятница', val: 4 },
    { opt: 'Суббота', val: 5 },
    { opt: 'Воскресенье', val: 6 },
  ],
  sendHrs: Array.from({ length: 24 }, (_, i) => ({ opt: `${i.toString().padStart(2, '0')}:00`, val: i })),
  channels: Array.from({ length: 8 }, (_, i) => ({ val: i + 1, opt: i + 1 })),
  monthdays: Array.from({ length: 31 }, (_, i) => ({ val: i + 1, opt: i + 1 })),
};

const EditMenu = ({ device, user, getDevices, devices }) => {
  const [showEditModal, setShowEditModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [deviceError, setDeviceError] = useState([]);

  const editDev = async () => {
    await setDevInputs();

    if (validateDeviceToEdit()) {
      await editDevice();
      await getDevices();
    }
  };

  const [deviceToEdit, setDeviceToEdit] = useState(device);
  const editDevice = async () => {
    setDeviceError([]);
    await editDeviceApi(
      deviceToEdit,
      (data) => {
        setDeviceError([]);
        setShowEditModal(false);
      },
      (err) => {
        toast.error(err.error, { hideProgressBar: true });
        setDeviceError((p) => [...p, err]);
      }
    );
  };

  const validateDeviceToEdit = () => {
    let ret = true;
    if (empty.test(deviceToEdit.serial_number)) {
      setDeviceError((p) => [...p, { error: 'Серийный номер пустой' }]);
      ret = false;
    }
    if (empty.test(deviceToEdit.description)) {
      setDeviceError((p) => [...p, { error: 'Описание пустое' }]);
      ret = false;
    }

    return ret;
  };

  const openEditModal = async () => {
    setDeviceToEdit(device);
    setDeviceError([]);
    setShowEditModal(true);
  };

  const [deviceInputs, setDeviceInputs] = useState(device.children);
  const setDevInputs = async () => {
    setDeviceError([]);
    await setDevInputsApi(
      deviceToEdit.id,
      deviceInputs,
      (data) => {},
      (err) => {
        toast.error(err.error, { hideProgressBar: true });
        setDeviceError((p) => [...p, err]);
      }
    );
  };

  const deleteDev = async () => {
    setDeviceError([]);
    await deleteDevApi(
      device.id,
      (data) => {
        //setShowAlert(false);
      },
      (err) => {
        toast.error(err.error, { hideProgressBar: true });
        setDeviceError((p) => [...p, err]);
      }
    );

    await delDevIntegrationApi2(
      device.id,
      (data) => {
        setShowAlert(false);
      },
      (err) => {
        toast.error(err.error, { hideProgressBar: true });
        setDeviceError((p) => [...p, err]);
      }
    );
  };

  const deleteConfiermed = async () => {
    await deleteDev();
    await getDevices();
  };

  return (
    <>
      <div
        onClick={() => setShowModal(true)}
        className="flex items-center justify-center bg-orange-500 hover:bg-orange-600 rounded-full p-1"
        style={{ width: 'fit-content' }}>
        <InfoOutlinedIcon className="text-white w-6 sm:w-6 xl:w-8 2xl:w-8 h-6 sm:h-6 xl:h-7 2xl:h-7" />
      </div>

      <div
        onClick={() => openEditModal()}
        className="flex items-center justify-center bg-orange-500 hover:bg-orange-600 rounded-full p-1"
        style={{ width: 'fit-content' }}>
        <ModeEditOutlineOutlinedIcon className="text-white w-6 sm:w-6 xl:w-8 2xl:w-8 h-6 sm:h-6 xl:h-7 2xl:h-7" />
      </div>

      <div
        onClick={() => setShowAlert(true)}
        className="flex items-center justify-center bg-orange-500 hover:bg-orange-600 rounded-full p-1"
        style={{ width: 'fit-content' }}>
        <DeleteForeverOutlinedIcon className="text-white w-6 sm:w-6 xl:w-8 2xl:w-8 h-6 sm:h-6 xl:h-7 2xl:h-7" />
      </div>

      <ConfirmAlert showAlert={showAlert} setShowAlert={setShowAlert} delCb={() => deleteConfiermed()} title="Удаление устройства" error={deviceError}>
        <AlertAccent text="Вы действительно хотите удалить устройство?" showAlert={true}>
          <DeviceNames device={device} style="line-clamp-1"></DeviceNames>
        </AlertAccent>
      </ConfirmAlert>

      <Modal
        showModal={showEditModal && user.verified_at}
        setShowModal={setShowEditModal}
        saveCb={() => editDev()}
        title="Редактирвание устройства"
        error={deviceError}
        style={1}>
        {device.type.key === 'controller' ? (
          <ControllerEditor
            setDeviceToEdit={setDeviceToEdit}
            deviceToEdit={deviceToEdit}
            devices={devices}
            setError={setDeviceError}
            deviceInputs={deviceInputs}
            setDeviceInputs={setDeviceInputs}
            innitialConfiguration={innitialConfiguration}></ControllerEditor>
        ) : (
          <AnyDeviceEditor setDeviceToEdit={setDeviceToEdit} deviceToEdit={deviceToEdit}></AnyDeviceEditor>
        )}
      </Modal>

      <Modal showModal={showModal} setShowModal={setShowModal} title="Карточка устройства">
        <DeviceCard device={device}></DeviceCard>
      </Modal>

      <div className="absolute top-0 left-0">
        <ToastContainer />
      </div>
    </>
  );
};

export default EditMenu;
