import React from 'react';

const AlertAccent = ({ children, title, text, showAlert }) => {
  return (
    <>
      {showAlert ? (
        <>
          <div class="px-4 py-3 leading-normal text-white bg-red-500 hover:bg-red-600 rounded-3xl cursor-pointer" role="alert">
            <p class="font-bold">{title}</p>
            <p>{text}</p>
            <p>{children}</p>
          </div>
        </>
      ) : null}
    </>
  );
};

export default AlertAccent;
