import React from 'react';
import Input from '../components/Input';
import Dropdown from '../components/Dropdown';
import Accordion from '../components/Accordion';
import BtnFilled from '../components/BtnFilled';
import DnsOutlinedIcon from '@mui/icons-material/DnsOutlined';
import CableIcon from '@mui/icons-material/Cable';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import ConfirmAlert from '../components/ConfirmAlert';
import AlertAccent from '../components/AlertErrorBox';

const DeviceEditor = ({ setDeviceToEdit, deviceToEdit, devices, setError, deviceInputs, setDeviceInputs, innitialConfiguration }) => {
  const [accordion, setAccordion] = React.useState({ 0: true });

  const innitialInput = { ...devices[0], ...{ input_number: 1, device_id: devices[0].id, modifiers: { add: 0, multiplier: 1 } } };
  const [inputToAdd, setInputToAdd] = React.useState(innitialInput);

  const [showAlert, setShowAlert] = React.useState(false);

  const [inputToDelete, setInputToDelete] = React.useState();

  const onDevInputAdd = () => {
    var found = false;
    setError([]);
    deviceInputs.map((input, i) => {
      if (input.input_number === inputToAdd.input_number) {
        found = true;
        setError((p) => [...p, { error: 'Выберите свободный канал' }]);
      }
      if (input.device_id === inputToAdd.device_id) {
        found = true;
        setError((p) => [...p, { error: 'Выберите свободное устройство' }]);
      }
    });

    if (!found) setDeviceInputs([...deviceInputs, inputToAdd]);
  };

  const setDevData = (e) => {
    var devToAdd = {};
    devices.map((dev, i) => {
      if (dev.id === e) devToAdd = dev;
    });

    devToAdd.device_id = devToAdd.id;

    setInputToAdd({ ...inputToAdd, ...devToAdd });
  };

  const setIntervalType = (e) => {
    setDeviceToEdit({
      ...deviceToEdit,
      config: { ...deviceToEdit.config, conn_rule: [...deviceToEdit.config.conn_rule.map((r, i) => (i === 0 ? e : 1))] },
    });
  };

  const deleteInput = () => {
    setDeviceInputs(deviceInputs.filter((a) => a.input_number !== inputToDelete));
    setShowAlert(false);
  };

  return (
    <>
      <Accordion accordion={accordion} setAccordion={setAccordion} id={'one'} Icon={DnsOutlinedIcon} name={'Общие настройки'}>
        <div class="px-4 sm:px-4 lg:px-5 xl:px-8 2xl:px-10">
          <div class="w-full">
            <Input
              value={deviceToEdit.serial_number}
              inputCb={(e) => setDeviceToEdit({ ...deviceToEdit, serial_number: e.target.value })}
              label="Серийный номер"
              id="type"
              type="text"
              placeholder={'A123'}></Input>
          </div>
          <div class="w-full  mt-4">
            <Input
              value={deviceToEdit.description}
              inputCb={(e) => setDeviceToEdit({ ...deviceToEdit, description: e.target.value })}
              label="Описание"
              id="descr"
              type="text"
              placeholder={'Контроллер'}></Input>
          </div>
          <div class="w-full mt-4">
            <Dropdown
              label={'Период записи'}
              options={innitialConfiguration.writingIntervals}
              value={deviceToEdit?.config?.write_every}
              inputCb={(e) => setDeviceToEdit({ ...deviceToEdit, config: { ...deviceToEdit.config, write_every: parseInt(e.target.value) } })}
              id="int"
              valkey="val"
              optkey="opt"></Dropdown>
          </div>

          <div class="w-full mt-4">
            <Dropdown
              label={'Тип интервала'}
              options={innitialConfiguration.intType}
              value={deviceToEdit?.config?.conn_rule[0]}
              inputCb={(e) => setIntervalType(e.target.value)}
              id="inttype"
              valkey="val"
              optkey="opt"></Dropdown>
          </div>

          {deviceToEdit?.config?.conn_rule[0] === 'D' && (
            <>
              <div class="w-full my-4">
                <Dropdown
                  label={'Час отправки показаний (UTC)'}
                  options={innitialConfiguration.sendHrs}
                  value={deviceToEdit?.config?.conn_rule[2]}
                  inputCb={(e) =>
                    setDeviceToEdit({
                      ...deviceToEdit,
                      config: { ...deviceToEdit.config, conn_rule: [...deviceToEdit.config.conn_rule.map((r, i) => (i === 2 ? parseInt(e.target.value) : r))] },
                    })
                  }
                  id="hrs"
                  valkey="val"
                  optkey="opt"></Dropdown>
              </div>
            </>
          )}
          {deviceToEdit?.config?.conn_rule[0] === 'W' && (
            <>
              <div class="w-full my-4">
                <Dropdown
                  label={'День недели'}
                  options={innitialConfiguration.weekDay}
                  value={deviceToEdit?.config?.conn_rule[1]}
                  inputCb={(e) =>
                    setDeviceToEdit({
                      ...deviceToEdit,
                      config: { ...deviceToEdit.config, conn_rule: [...deviceToEdit.config.conn_rule.map((r, i) => (i === 1 ? parseInt(e.target.value) : r))] },
                    })
                  }
                  id="hrs"
                  valkey="val"
                  optkey="opt"></Dropdown>
              </div>
              <div class="w-full my-4">
                <Dropdown
                  label={'Час отправки показаний (UTC)'}
                  options={innitialConfiguration.sendHrs}
                  value={deviceToEdit?.config?.conn_rule[2]}
                  inputCb={(e) =>
                    setDeviceToEdit({
                      ...deviceToEdit,
                      config: { ...deviceToEdit.config, conn_rule: [...deviceToEdit.config.conn_rule.map((r, i) => (i === 2 ? parseInt(e.target.value) : r))] },
                    })
                  }
                  id="hrs"
                  valkey="val"
                  optkey="opt"></Dropdown>
              </div>
            </>
          )}
          {deviceToEdit?.config?.conn_rule[0] === 'M' && (
            <>
              <div class="w-full my-4">
                <Dropdown
                  label={'День месяца'}
                  options={innitialConfiguration.monthdays}
                  value={deviceToEdit?.config?.conn_rule[1]}
                  inputCb={(e) =>
                    setDeviceToEdit({
                      ...deviceToEdit,
                      config: { ...deviceToEdit.config, conn_rule: [...deviceToEdit.config.conn_rule.map((r, i) => (i === 1 ? parseInt(e.target.value) : r))] },
                    })
                  }
                  id="hrs"
                  valkey="val"
                  optkey="opt"></Dropdown>
              </div>
              <div class="w-full my-4">
                <Dropdown
                  label={'Час отправки показаний (UTC)'}
                  options={innitialConfiguration.sendHrs}
                  value={deviceToEdit?.config?.conn_rule[2]}
                  inputCb={(e) =>
                    setDeviceToEdit({
                      ...deviceToEdit,
                      config: { ...deviceToEdit.config, conn_rule: [...deviceToEdit.config.conn_rule.map((r, i) => (i === 2 ? parseInt(e.target.value) : r))] },
                    })
                  }
                  id="hrs"
                  valkey="val"
                  optkey="opt"></Dropdown>
              </div>
            </>
          )}
        </div>
      </Accordion>

      <Accordion accordion={accordion} setAccordion={setAccordion} id={'two'} Icon={CableIcon} name={'Настройка каналов'}>
        <div class="px-4 sm:px-4 lg:px-5 xl:px-8 2xl:px-10">
          <table class="table-auto w-full text-base mb-4">
            <thead>
              <tr>
                <th>
                  <p class="w-12 sm:w-32 lg:w-32 xl:w-auto 2xl:w-auto truncate text-xs sm:text-sm">Канал</p>
                </th>
                <th>
                  <p class="w-16 sm:w-32 lg:w-32 xl:w-auto 2xl:w-auto truncate text-xs sm:text-sm">Устройство</p>
                </th>
                <th>
                  <p class="w-12 sm:w-32 lg:w-32 xl:w-auto 2xl:w-auto truncate text-xs sm:text-sm">Начальное значение</p>
                </th>
                <th>
                  <p class="w-12 sm:w-32 lg:w-32 xl:w-auto 2xl:w-auto truncate text-xs sm:text-sm">Коэффициент</p>
                </th>
                <th>
                  <p class="w-10 sm:w-32 lg:w-32 xl:w-auto 2xl:w-auto truncate text-xs sm:text-sm">Удалить</p>
                </th>
              </tr>
            </thead>
            <tbody>
              {deviceInputs.map((inp, index) => {
                return (
                  <tr key={index}>
                    <td class="text-center border-y">{inp.input_number}</td>
                    <td class="text-center border-y">{inp.serial_number}</td>
                    <td class="text-center border-y">{inp?.modifiers?.add}</td>
                    <td class="text-center border-y">{inp?.modifiers?.multiplier}</td>
                    <td class="text-center border-y">
                      <DeleteForeverOutlinedIcon
                        onClick={() => (setInputToDelete(inp.input_number), setShowAlert(true))}
                        class="w-6 h-6  fill-black hover:fill-orange-500"></DeleteForeverOutlinedIcon>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>

          <div class="w-full mb-4">
            <Dropdown
              value={inputToAdd.input_number}
              label={'Выбрать канал'}
              options={innitialConfiguration.channels}
              inputCb={(e) => setInputToAdd({ ...inputToAdd, input_number: parseInt(e.target.value) })}
              id="ch"
              valkey="val"
              optkey="opt"></Dropdown>
          </div>

          <div class="w-full mb-4">
            <Dropdown
              label={'Выбрать устройство'}
              options={devices}
              inputCb={(e) => setDevData(parseInt(e.target.value))}
              id="devs"
              valkey="id"
              optkey="serial_number"></Dropdown>
          </div>
          <div class="w-full mb-4">
            <Input
              value={inputToAdd.modifiers.add}
              inputCb={(e) => setInputToAdd({ ...inputToAdd, modifiers: { ...inputToAdd.modifiers, add: parseInt(e.target.value) } })}
              label="Начальное значение"
              id="val"
              type="number"
              placeholder={'0'}></Input>
          </div>

          <div class="w-full mb-4">
            <Input
              value={inputToAdd.modifiers.multiplier}
              inputCb={(e) => setInputToAdd({ ...inputToAdd, modifiers: { ...inputToAdd.modifiers, multiplier: parseInt(e.target.value) } })}
              label="Коэффициент"
              id="coef"
              type="number"
              placeholder={'1'}></Input>
          </div>

          <div class="w-full mb-4">
            <BtnFilled title="Добавить" btnCb={() => onDevInputAdd()}></BtnFilled>
          </div>
        </div>
      </Accordion>
      <ConfirmAlert showAlert={showAlert} setShowAlert={setShowAlert} delCb={() => deleteInput()} title="Удаление канала">
        <AlertAccent text="Вы действительно хотите удалить канал?" showAlert={true}>
          <p>Номер канала: {inputToDelete}</p>
        </AlertAccent>
      </ConfirmAlert>
    </>
  );
};

export default DeviceEditor;
