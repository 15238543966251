//this is counter widget
import React from 'react';
//line-clamp-1 text-lg font-bold text-center
const DeviceNames = ({ device, style }) => {
  return (
    <>
      {device?.type?.sub_type?.key === 'counter:water:cold' && <p class={style}>{'Счетчик (холодная вода) - ' + device.serial_number} </p>}
      {device?.type?.sub_type?.key === 'counter:water:hot' && <p class={style}>{'Счетчик (горячая вода) - ' + device.serial_number} </p>}
      {device?.type?.sub_type?.key === 'counter:water' && <p class={style}>{'Счетчик (универсальный) - ' + device.serial_number} </p>}
      {device?.type?.key === 'controller' && <p class={style}>{'Контроллер - ' + device.serial_number} </p>}
      {device?.type?.sub_type?.key === 'sensor:water:leakage' && <p class={style}>{'Датчик протечки - ' + device.serial_number} </p>}
      {device?.type?.sub_type?.key === 'sensor:temperature' && <p class={style}>{'Датчик температуры - ' + device.serial_number} </p>}
    </>
  );
};

export default DeviceNames;
