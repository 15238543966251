import React from 'react';

import WaterDropIcon from '@mui/icons-material/WaterDrop';
import MemoryOutlinedIcon from '@mui/icons-material/MemoryOutlined';
import DeviceThermostatIcon from '@mui/icons-material/DeviceThermostat';
import ToggleOnOutlinedIcon from '@mui/icons-material/ToggleOnOutlined';
import ToggleOffOutlinedIcon from '@mui/icons-material/ToggleOffOutlined';

const DeviceIcons = ({ device }) => {
  return (
    <>
      {device?.type?.sub_type?.key === 'counter:water:cold' && (
        <div className="flex items-center justify-center bg-[#359BFF] rounded-full p-2" style={{ width: 'fit-content' }}>
          <WaterDropIcon className="text-white w-6 sm:w-6 xl:w-8 2xl:w-8 h-6 sm:h-6 xl:h-7 2xl:h-7" />
        </div>
      )}
      {device?.type?.sub_type?.key === 'counter:water:hot' && (
        <div className="flex items-center justify-center bg-[#FC3339] rounded-full p-2" style={{ width: 'fit-content' }}>
          <WaterDropIcon className="text-white w-6 sm:w-6 xl:w-8 2xl:w-8 h-6 sm:h-6 xl:h-7 2xl:h-7" />
        </div>
      )}
      {device?.type?.sub_type?.key === 'counter:water' && (
        <div className="flex items-center justify-center bg-gray-500 rounded-full p-2" style={{ width: 'fit-content' }}>
          <WaterDropIcon className="text-white w-6 sm:w-6 xl:w-8 2xl:w-8 h-6 sm:h-6 xl:h-7 2xl:h-7" />
        </div>
      )}
      {device?.type?.key === 'controller' && (
        <div className="flex items-center justify-center bg-green-500 rounded-full p-2" style={{ width: 'fit-content' }}>
          <MemoryOutlinedIcon className="text-white w-6 sm:w-6 xl:w-8 2xl:w-8 h-6 sm:h-6 xl:h-7 2xl:h-7" />
        </div>
      )}
      {device?.type?.sub_type?.key === 'sensor:water:leakage' && (
        <div className="flex items-center justify-center bg-orange-500 rounded-full p-2" style={{ width: 'fit-content' }}>
          {device?.last_event?.value ? (
            <ToggleOnOutlinedIcon className="text-white w-6 sm:w-6 xl:w-8 2xl:w-8 h-6 sm:h-6 xl:h-7 2xl:h-7" />
          ) : (
            <ToggleOffOutlinedIcon className="text-white w-6 sm:w-6 xl:w-8 2xl:w-8 h-6 sm:h-6 xl:h-7 2xl:h-7" />
          )}
        </div>
      )}
      {device?.type?.sub_type?.key === 'sensor:temperature' && (
        <div className="flex items-center justify-center bg-[#FC3339] rounded-full p-2" style={{ width: 'fit-content' }}>
          <DeviceThermostatIcon className="text-white w-6 sm:w-6 xl:w-8 2xl:w-8 h-6 sm:h-6 xl:h-7 2xl:h-7" />
        </div>
      )}
    </>
  );
};

export default DeviceIcons;
