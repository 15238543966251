import React, { useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';

//https://stackoverflow.com/questions/66180213/how-to-prevent-css-libraries-like-tailwindcss-and-bootstrap-to-effect-wysiwyg-ed

const HtmlEditor = ({ html, setHtml, config }) => {
  const editorRef = useRef(null);

  function onEditorChange(newText) {
    console.log(newText);
    let txt = html;
    txt.html = newText;
    setHtml(txt);
  }

  return (
    <>
      <div class="w-full mb-0 items-center">
        <>
          <Editor
            apiKey="voyivu07uirmnu3opp2wikc20q3w5ypnehoa0jfqvhkqihfb"
            onInit={(evt, editor) => (editorRef.current = editor)}
            initialValue={html.html}
            onEditorChange={(newText) => onEditorChange(newText)}
            init={{
              height: 600,
              width: 'auto',
              menubar: true,
              image_advtab: true,
              plugins: [
                'advlist',
                'autolink',
                'lists',
                'link',
                'image',
                'charmap',
                'preview',
                'anchor',
                'searchreplace',
                'visualblocks',
                'media mediaembed',
                'code',
                'fullscreen',
                'insertdatetime',
                'media',
                'table',
                'code',
                'help',
                'wordcount',
              ],

              language: 'ru',

              toolbar:
                'undo redo | blocks | bold italic forecolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | fontfamily fontsize | help',

              content_style: 'body { font-family:Helvetica, Arial, sans-serif; font-size:12px }',
            }}
          />
        </>
      </div>
    </>
  );
};

export default HtmlEditor;
