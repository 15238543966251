import React from 'react';
import { getObjectsApi, getDevicesObjApi, addIntegrationApi, getIntegrationsApi, delIntegrationApi } from '../api/api';
import { getIntegrationsApi2, addIntegrationApi2, delIntegrationApi2 } from '../api/api2';
import Input from '../components/Input';
import Dropdown from '../components/Dropdown';
import LoadingSpinner from '../components/LoadingSpinner';
import AlertAccent from '../components/AlertAccent';
import BtnFilled from '../components/BtnFilled';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import AlertError from '../components/AlertError';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const monthdays = [
  { val: 1, opt: 1 },
  { val: 2, opt: 2 },
  { val: 3, opt: 3 },
  { val: 4, opt: 4 },
  { val: 5, opt: 5 },
  { val: 6, opt: 6 },
  { val: 7, opt: 7 },
  { val: 8, opt: 8 },
  { val: 9, opt: 9 },
  { val: 10, opt: 10 },
  { val: 11, opt: 11 },
  { val: 12, opt: 12 },
  { val: 13, opt: 13 },
  { val: 14, opt: 14 },
  { val: 15, opt: 15 },
  { val: 16, opt: 16 },
  { val: 17, opt: 17 },
  { val: 18, opt: 18 },
  { val: 19, opt: 19 },
  { val: 20, opt: 20 },
  { val: 21, opt: 21 },
  { val: 22, opt: 22 },
  { val: 23, opt: 23 },
  { val: 24, opt: 24 },
  { val: 25, opt: 25 },
  { val: 26, opt: 26 },
  { val: 27, opt: 27 },
  { val: 28, opt: 28 },
];

const initIntegrations = [
  {
    i: 0,
    type: 'reporter.mail',
    name: 'Оповещения на email',
    config: { send_at_day: 1, send_to: 'example@yandex.ru' },
    supportsBy: ['counter:water:cold', 'counter:water:hot', 'counter:water', 'sensor:water:leakage'],
  },
  {
    i: 1,
    type: 'mosru',
    name: 'Интеграция с mos.ru',
    config: { send_at_day: 1 },
    supportsBy: ['counter:water:cold', 'counter:water:hot', 'counter:water'],
  },
  {
    i: 2,
    type: 'mvk',
    name: 'Интеграция с Мосводоканал',
    config: { send_at_day: 1, dog_tag: '90037798' },
    supportsBy: ['counter:water:cold', 'counter:water:hot', 'counter:water'],
  },
];

const Integrations = ({ user }) => {
  const [objects, setObjects] = React.useState([]);
  const [loadErr, setLoadErr] = React.useState([]);
  const [loading, setLoading] = React.useState({ getobjects: true });
  const [deviceId, setDeviceId] = React.useState();
  const [integrationType, setIntegrationType] = React.useState(initIntegrations[0].type);
  const [selectedDev, setSelectedDev] = React.useState({});
  const [availableIntegrations, setAvailableIntegrations] = React.useState(initIntegrations);
  const [integConf, setIntegConf] = React.useState(initIntegrations[0].config);

  const [mailReporterSubtype, setMailReporterSubtype] = React.useState('time');

  React.useEffect(() => {
    const get = async () => {
      await getObj();
    };
    get();
  }, []);

  React.useEffect(() => {
    const deviceType = selectedDev?.type?.sub_type?.key || selectedDev?.type?.key;
    const filteredIntegrations = initIntegrations.filter((integration) => integration.supportsBy.includes(deviceType));
    setAvailableIntegrations(filteredIntegrations);
  }, [selectedDev]);

  const getObj = async () => {
    setLoadErr([]);
    await getObjectsApi(
      async function (data) {
        setLoading({ ...loading, getobjects: false });
        if (data && data.length !== 0) {
          setObjects(data);
          await getDevices(data[0].id);
        }
      },
      function (err) {
        toast.error(err.error, { hideProgressBar: true });
        setLoading({ ...loading, getobjects: false });
        setLoadErr((p) => [...p, err]);
      }
    );
  };

  const [devices, setDevices] = React.useState([]);
  const getDevices = async (objectId) => {
    setLoadErr([]);
    await getDevicesObjApi(
      async function (data) {
        setDevices(data);
        setSelectedDev(data[0]);
        if (data && data.length !== 0) {
          setDeviceId(data[0].id);
          await getIntegrations(data[0].id);
        }
      },
      function (err) {
        toast.error(err.error, { hideProgressBar: true });
        setLoadErr((p) => [...p, err]);
      },
      objectId
    );
  };

  const addIntegration = async () => {
    setLoadErr([]);
    await addIntegrationApi(
      integConf,
      integrationType,
      deviceId,
      async function (data) {
        await getIntegrations(deviceId);
      },
      function (err) {
        toast.error(err.error, { hideProgressBar: true });
        setLoadErr((p) => [...p, err]);
      }
    );
  };

  const addIntegration2 = async () => {
    setLoadErr([]);
    await addIntegrationApi2(
      integConf,
      integrationType,
      deviceId,
      async function (data) {
        await getIntegrations(deviceId);
      },
      function (err) {
        toast.error(err.error, { hideProgressBar: true });
        setLoadErr((p) => [...p, err]);
      }
    );
  };

  const [integrations, setIntegrations] = React.useState([]);
  const getIntegrations = async (devId) => {
    setLoadErr([]);
    setIntegrations([]);
    await getIntegrationsApi(
      function (data) {
        setIntegrations((p) => [...p, ...data]);
      },
      function (err) {
        toast.error(err.error, { hideProgressBar: true });
        setLoadErr((p) => [...p, err]);
      },
      devId
    );

    await getIntegrationsApi2(
      function (data) {
        setIntegrations((p) => [...p, ...data]);
      },
      function (err) {
        toast.error(err.error, { hideProgressBar: true });
        setLoadErr((p) => [...p, err]);
      },
      devId
    );
  };

  const delIntegration = async (int) => {
    setLoadErr([]);

    if (int.key === 'mvk') {
      await delIntegrationApi2(
        int._id,
        int.key,
        int.device_id,
        async function (data) {
          await getIntegrations(deviceId);
        },
        function (err) {
          toast.error(err.error, { hideProgressBar: true });
          setLoadErr((p) => [...p, err]);
        }
      );
    } else {
      await delIntegrationApi(
        int.id,
        int.key,
        int.device_id,
        async function (data) {
          await getIntegrations(deviceId);
        },
        function (err) {
          toast.error(err.error, { hideProgressBar: true });
          setLoadErr((p) => [...p, err]);
        }
      );
    }
  };

  const onObjectSelect = async (objectId) => {
    await getDevices(objectId);
  };

  const onDeviceSelect = async (selectedDev) => {
    //костыль что бы не менять backend
    if (selectedDev?.type?.sub_type?.key === 'sensor:water:leakage') {
      setMailReporterSubtype('event');
    } else {
      setMailReporterSubtype('time');
    }
    setSelectedDev(selectedDev);
    setDeviceId(selectedDev.id);
    await getIntegrations(selectedDev.id);
  };

  const onIntegrationSelect = async (i) => {
    setIntegrationType(initIntegrations[i].type);
    setIntegConf(initIntegrations[i].config);
  };

  return (
    <>
      <div>
        <ToastContainer />
      </div>

      <div className="sm:flex min-h-screen items-start justify-center bg-gradient-to-r p-2 sm:p-4 from-[#ededed] to-[#dcdcdc]">
        {!loading.getobjects ? (
          <div className="w-full max-w-sm sm:max-w-sm md:max-w-xl lg:max-w-2xl xl:max-w-4xl 2xl:max-w-5xl">
            <div className="flex items-center space-x-4 my-4">
              <p className="truncate text-3xl font-normal">Настройки интеграций</p>
            </div>
            <div className="bg-white shadow-3xl rounded-3xl p-4">
              {objects.length !== 0 ? (
                <>
                  <div className="w-full mb-4">
                    <Dropdown
                      label={'Выберите объект'}
                      options={objects}
                      inputCb={(e) => onObjectSelect(parseInt(e.target.value))}
                      id="devs"
                      valkey="id"
                      optkey="address"></Dropdown>
                  </div>
                  <div className="w-full mb-4">
                    <Dropdown
                      label={'Выберите устройство'}
                      options={devices}
                      arrayElementCb={(e) => onDeviceSelect(e)}
                      id="devs"
                      valkey="id"
                      emptyoption="К объекту не привязано ни одного устройства"
                      disabled={devices.length === 0}
                      optkey="serial_number"
                      optkey2="type.name"></Dropdown>
                  </div>

                  {availableIntegrations.length !== 0 ? (
                    <>
                      <div className="w-full mb-4">
                        <Dropdown
                          label={'Выберите тип интеграции'}
                          options={availableIntegrations}
                          inputCb={(e) => onIntegrationSelect(parseInt(e.target.value))}
                          id="ints"
                          valkey="i"
                          disabled={devices.length === 0}
                          optkey="name"></Dropdown>
                      </div>

                      {devices.length !== 0 && (
                        <>
                          {integrationType === 'reporter.mail' && (
                            <>
                              <>
                                {mailReporterSubtype === 'time' && (
                                  <>
                                    <table className="table-auto w-full text-base mb-4">
                                      <thead>
                                        <tr>
                                          <th>
                                            <p className="w-12 sm:w-32 lg:w-32 xl:w-auto 2xl:w-auto truncate text-xs sm:text-sm">Email для оповещения</p>
                                          </th>
                                          <th>
                                            <p className="w-16 sm:w-32 lg:w-32 xl:w-auto 2xl:w-auto truncate text-xs sm:text-sm">День оповещения</p>
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {integrations.length !== 0 && (
                                          <>
                                            {integrations.map((int, index) => {
                                              return (
                                                <>
                                                  {int.key === 'reporter.mail' && (
                                                    <tr key={index + int.key}>
                                                      <td className="text-center border-y">{int.cfg.send_to}</td>
                                                      <td className="text-center border-y">{int.cfg.send_at_day}</td>

                                                      <td className="text-center border-y">
                                                        <DeleteForeverOutlinedIcon
                                                          onClick={() => delIntegration(int)}
                                                          className="w-6 h-6  fill-black hover:fill-orange-500"></DeleteForeverOutlinedIcon>
                                                      </td>
                                                    </tr>
                                                  )}
                                                </>
                                              );
                                            })}
                                          </>
                                        )}
                                      </tbody>
                                    </table>

                                    <div className="mb-4">
                                      <Input
                                        label="Email для оповещения"
                                        value={integConf?.send_to}
                                        inputCb={(e) => setIntegConf({ ...integConf, send_to: e.target.value })}
                                        id="email"
                                        type="text"
                                        placeholder={'someone@example.com'}></Input>
                                    </div>
                                    <div className="w-full my-4">
                                      <Dropdown
                                        label={'День оповещения'}
                                        options={monthdays}
                                        value={integConf?.send_at_day}
                                        inputCb={(e) => setIntegConf({ ...integConf, send_at_day: parseInt(e.target.value) })}
                                        id="hrs"
                                        valkey="val"
                                        optkey="opt"></Dropdown>
                                    </div>
                                    <div className="w-full mb-0">
                                      <BtnFilled title="Добавить" btnCb={() => addIntegration()}></BtnFilled>
                                    </div>
                                  </>
                                )}
                              </>
                              <>
                                {mailReporterSubtype === 'event' && (
                                  <>
                                    <table className="table-auto w-full text-base mb-4">
                                      <thead>
                                        <tr>
                                          <th className="text-center">
                                            <p className="w-12 sm:w-32 lg:w-32 xl:w-auto 2xl:w-auto text-xs sm:text-sm">Email для оповещения</p>
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {integrations.length !== 0 && (
                                          <>
                                            {integrations.map((int, index) => {
                                              return (
                                                <>
                                                  {int.key === 'reporter.mail' && (
                                                    <tr key={index + int.key}>
                                                      <td className="text-center border-y">{int.cfg.send_to}</td>

                                                      <td className="text-center border-y">
                                                        <DeleteForeverOutlinedIcon
                                                          onClick={() => delIntegration(int)}
                                                          className="w-6 h-6  fill-black hover:fill-orange-500"></DeleteForeverOutlinedIcon>
                                                      </td>
                                                    </tr>
                                                  )}
                                                </>
                                              );
                                            })}
                                          </>
                                        )}
                                      </tbody>
                                    </table>

                                    <div className="mb-4">
                                      <Input
                                        label="Email для оповещения"
                                        value={integConf?.send_to}
                                        inputCb={(e) => setIntegConf({ ...integConf, send_to: e.target.value })}
                                        id="email"
                                        type="text"
                                        placeholder={'someone@example.com'}></Input>
                                    </div>

                                    <div className="w-full mb-0">
                                      <BtnFilled title="Добавить" btnCb={() => addIntegration()}></BtnFilled>
                                    </div>
                                  </>
                                )}
                              </>
                            </>
                          )}
                          {integrationType === 'mosru' && (
                            <>
                              <table className="table-auto w-full text-base mb-4">
                                <thead>
                                  <tr>
                                    <th>
                                      <p className="w-16 sm:w-32 lg:w-32 xl:w-auto 2xl:w-auto truncate text-xs sm:text-sm">День отправки показаний</p>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {integrations.length !== 0 && (
                                    <>
                                      {integrations.map((int, index) => {
                                        return (
                                          <>
                                            {int.key === 'mosru' && (
                                              <tr key={index + int.key}>
                                                <td className="text-center border-y">{int.cfg.send_at_day}</td>

                                                <td className="text-center border-y">
                                                  <DeleteForeverOutlinedIcon
                                                    onClick={() => delIntegration(int)}
                                                    className="w-6 h-6  fill-black hover:fill-orange-500"></DeleteForeverOutlinedIcon>
                                                </td>
                                              </tr>
                                            )}
                                          </>
                                        );
                                      })}
                                    </>
                                  )}
                                </tbody>
                              </table>

                              <div className="w-full my-4">
                                <Dropdown
                                  label={'День отправки показаний'}
                                  options={monthdays}
                                  value={integConf?.send_at_day}
                                  inputCb={(e) => setIntegConf({ ...integConf, send_at_day: parseInt(e.target.value) })}
                                  id="hrs"
                                  valkey="val"
                                  optkey="opt"></Dropdown>
                              </div>
                              <div className="w-full mb-0">
                                <BtnFilled title="Добавить" btnCb={() => addIntegration()}></BtnFilled>
                              </div>
                            </>
                          )}
                          {integrationType === 'mvk' && (
                            <>
                              <table className="table-auto w-full text-base mb-4">
                                <thead>
                                  <tr>
                                    <th>
                                      <p className="w-12 sm:w-32 lg:w-32 xl:w-auto 2xl:w-auto truncate text-xs sm:text-sm">Номер договора</p>
                                    </th>
                                    <th>
                                      <p className="w-16 sm:w-32 lg:w-32 xl:w-auto 2xl:w-auto truncate text-xs sm:text-sm">День отправки показаний</p>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {integrations.length !== 0 && (
                                    <>
                                      {integrations.map((int, index) => {
                                        return (
                                          <>
                                            {int.key === 'mvk' && (
                                              <tr key={index + 300}>
                                                <td className="text-center border-y">{int.cfg.dog_tag}</td>
                                                <td className="text-center border-y">{int.cfg.send_at_day}</td>

                                                <td className="text-center border-y">
                                                  <DeleteForeverOutlinedIcon
                                                    onClick={() => delIntegration(int)}
                                                    className="w-6 h-6  fill-black hover:fill-orange-500"></DeleteForeverOutlinedIcon>
                                                </td>
                                              </tr>
                                            )}
                                          </>
                                        );
                                      })}
                                    </>
                                  )}
                                </tbody>
                              </table>

                              <div className="w-full my-4">
                                <Dropdown
                                  label={'День отправки показаний'}
                                  options={[{ val: 1, opt: 'Всегда первое число месяца' }]}
                                  value={integConf?.send_at_day}
                                  inputCb={(e) => setIntegConf({ ...integConf, send_at_day: parseInt(e.target.value) })}
                                  id="hrs"
                                  valkey="val"
                                  optkey="opt"></Dropdown>
                              </div>
                              <div className="w-full my-4">
                                <Input
                                  label="Номер договора"
                                  value={integConf?.dog_tag}
                                  inputCb={(e) => setIntegConf({ ...integConf, dog_tag: e.target.value })}
                                  id="dog_tag"
                                  type="text"
                                  placeholder={'90037798'}></Input>
                              </div>
                              <div className="w-full mb-0">
                                <BtnFilled title="Добавить" btnCb={() => addIntegration2()}></BtnFilled>
                              </div>
                            </>
                          )}
                        </>
                      )}
                    </>
                  ) : (
                    <AlertAccent title="Для данного устройства нет доступных интеграций" text="Выберите другое устройство" showAlert={true}></AlertAccent>
                  )}
                </>
              ) : (
                <AlertAccent
                  title="Объекты не созданны!"
                  text="Для настройки интеграций Вам необходимо добавить хотябы один объект"
                  showAlert={true}></AlertAccent>
              )}
            </div>
          </div>
        ) : (
          <LoadingSpinner error={loadErr} loading={true}></LoadingSpinner>
        )}
      </div>
    </>
  );
};

export default Integrations;
