import * as React from 'react';
import Login from './Login';
import Registration from './Registration';

const Authorization = ({ setUser, setAuthorized }) => {
  const [_user, _setUser] = React.useState({
    username: 'flassie6',
    password: '123',
  });
  const [_error, _setError] = React.useState({});

  const tabActiveStyle = (index, length) => {
    let style =
      'inline-block p-1 w-full text-base text-gray-800 border-t border-x border-gray-300 bg-white font-bold active cursor-pointer select-none truncate';
    if (index === 0) {
      style += ' rounded-tl-3xl';
    } else if (index === length - 1) {
      style += ' rounded-tr-3xl';
    }
    return style;
  };

  const tabInactiveStyle =
    'inline-block p-1 w-full text-base bg-opacity-0 border-b border-gray-300 text-gray-600 hover:text-gray-800 font-bold cursor-pointer select-none truncate';

  const [tabs, setTabs] = React.useState([{ name: 'Вход', active: true }, { name: 'Регистрация' }]);

  const makeActive = (index) => {
    setTabs([...tabs.map((tab, i) => ({ ...tab, active: index === i ? true : false }))]);
  };

  return (
    <div className="flex w-full min-h-screen p-4 items-start justify-center bg-gradient-to-r from-[#ededed] to-[#dcdcdc]">
      <div className="w-full max-w-lg">
        <div className="rounded-3xl m-2">
          <div className="text-sm font-medium text-center text-gray-500 dark:text-gray-400 dark:border-gray-700">
            <ul className="flex flex-nowrap">
              {tabs.map((tab, index) => {
                return (
                  <p
                    key={index}
                    onClick={() => {
                      makeActive(index);
                    }}
                    className={tab.active ? tabActiveStyle(index, tabs.length) : tabInactiveStyle}>
                    {tab.name}
                  </p>
                );
              })}
            </ul>
          </div>
          {tabs[0].active && <Login setUser={setUser} setAuthorized={setAuthorized}></Login>}
          {tabs[1].active && <Registration setUser={setUser} setAuthorized={setAuthorized}></Registration>}
        </div>
        <p className="text-center text-gray-500 text-xs select-none">&copy; Все права защищены</p>
      </div>
    </div>
  );
};

export default Authorization;
