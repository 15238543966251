import * as React from 'react';
import { logout, getUserApi } from '../api/api';
import { useNavigate, useLocation } from 'react-router-dom';
import AlertAccent from '../components/AlertAccent';
import BtnFilled from '../components/BtnFilled';

const User = ({ user, setUser, setAuthorized }) => {
  const navigate = useNavigate();
  const out = async () => {
    await logout();
    await getUserApi(
      function (data) {
        setUser(data);
        setAuthorized(true);
      },
      function (err) {
        setAuthorized(false);
      }
    );
    navigate('/');
  };
  return (
    <div class="flex w-full min-h-screen p-4 items-start justify-center bg-gradient-to-r from-[#ededed] to-[#dcdcdc]">
      <div class="w-full max-w-lg">
        <form class="bg-white shadow-2xl rounded-3xl px-8 pt-6 pb-8 mb-4">
          <div>
            <AlertAccent
              title="Аккаунт не подтвержден!"
              text="Для подтверждения аккаунта перейдите пожалуйста по ссылке, отправленной на Вашу почту"
              showAlert={!user.verified_at}></AlertAccent>

            <table class="table-auto w-full text-base my-4">
              <tbody>
                <tr>
                  <td class="text-center border-y">
                    <p class="line-clamp-1">Email или логин</p>
                  </td>
                  <td class="text-center border-y">{user.username}</td>
                </tr>
                <tr>
                  <td class="text-center border-y">
                    <p class="line-clamp-1">ФИO</p>
                  </td>
                  <td class="text-center border-y">{user.fio}</td>
                </tr>
                <tr>
                  <td class="text-center border-y">
                    <p class="line-clamp-1">Телефон</p>
                  </td>
                  <td class="text-center border-y">{user.phone}</td>
                </tr>
                <tr>
                  <td class="text-center border-y">
                    <p class="line-clamp-1">Роль</p>
                  </td>
                  <td class="text-center border-y">{user.role}</td>
                </tr>
                <tr>
                  <td class="text-center border-y">
                    <p class="line-clamp-1">Статус</p>
                  </td>
                  <td class="text-center border-y">{user.verified_at ? 'Подтвержден' : 'Не подтвержден'}</td>
                </tr>
                <tr>
                  <td class="text-center border-y">
                    <p class="line-clamp-1">Версия web</p>
                  </td>
                  <td class="text-center border-y">
                    {process.env.REACT_APP_VER} ({process.env.REACT_APP_MODE})
                  </td>
                </tr>
              </tbody>
            </table>

            <BtnFilled title="Выход" btnCb={out}></BtnFilled>
          </div>
        </form>
      </div>
    </div>
  );
};

export default User;
