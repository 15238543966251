import React, { createRef } from 'react';
import BtnOutlined from '../components/BtnOutlined';

const initialStatus = {
  pic: false,
  st: false,
  path: false,
};

const FileUploaderBody = ({}) => {
  const fileInput = createRef();
  const [status, setStatus] = React.useState(initialStatus);

  const onInput = (e) => {
    setStatus({
      ...status,
      path: fileInput.current.files[0],
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.set('avatar', fileInput.current.files[0]);

    try {
      const response = await fetch(process.env.REACT_APP_BASE_URL2 + 'profile', {
        method: 'POST',
        body: formData,
      });

      const parcedResponce = await response.json();

      if (response.ok) {
        setStatus({
          ...status,
          pic: parcedResponce.link,
          st: true,
        });

        console.log(parcedResponce.link);
      } else {
        setStatus({
          ...status,
          st: false,
        });
      }
    } catch (e) {
      setStatus({
        ...status,
        st: false,
      });
    }
  };

  return (
    <div class="grid grid-cols-1 gap-6">
      <div>
        {status.pic && (
          <figure>
            <img src={status.pic} className="w-1/2 mb-6" />
          </figure>
        )}

        {status.st && (
          <div
            className={
              status.st
                ? 'bg-green-500 text-white text-center border me-2 px-2.5 py-0.5 rounded-full h-8 w-1/6 line-clamp-1 mb-6'
                : 'bg-red-500 text-white text-center border me-2 px-2.5 py-0.5 rounded-full h-8 w-1/6 line-clamp-1 mb-6'
            }>
            {status.st ? 'Успешно' : 'Ошибка'}
          </div>
        )}

        <div className="card-actions flex flex-col justify-center">
          {status.pic && (
            <input
              type="text"
              value={status.pic}
              placeholder=""
              className="appearance-none border border-gray-800 pl-4 rounded-full w-full h-10 px-3 text-gray-700 leading-tight focus:outline-none mb-6"
            />
          )}

          {!status.st && (
            <>
              <input onChange={(e) => onInput(e)} className="mb-6" type="file" name="avatar" ref={fileInput}></input>

              {status.path && (
                <button
                  onClick={onSubmit}
                  className="bg-transparent w-full truncate border border-gray-800 hover:bg-orange-50 text-black font-semibold hover:text-orange-500 py-2 px-4  hover:border-orange-500 rounded-full"
                  type="submit">
                  Загрузить фото
                </button>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default FileUploaderBody;
