//this is counter widget
import React from 'react';

import DeviceIcons from './DeviceIcons';
import DeviceNames from './DeviceNames';
import DeviceValues from './DeviceValues';
import DeviceStatuses from './DeviceStatuses';

import CheckIcon from '@mui/icons-material/CheckCircle';
import OutlinedIcon from '@mui/icons-material/DoDisturbAltOutlined';
import ApartmentIcon from '@mui/icons-material/Apartment';

const DeviceCard = ({ device }) => {
  return (
    <>
      <DeviceNames device={device} style="line-clamp-1 text-lg font-bold text-center"></DeviceNames>

      <div class="flex items-center justify-center my-2">
        <DeviceStatuses device={device} style="bg-orange-50 font-bold text-gray-800 me-2 px-2.5 py-0.5 rounded-full line-clamp-1"></DeviceStatuses>
      </div>
      <table class="table-auto w-full text-base my-4">
        <tbody>
          <tr>
            <td class="text-start border-y">
              <p class="line-clamp-1">Устройство</p>
            </td>
            <td class="text-start border-y">
              <p class="ml-4">{device?.type?.name}</p>
            </td>
          </tr>
          {device?.type?.key !== 'controller' && (
            <tr>
              <td class="text-start border-y">
                <p class="line-clamp-1">Текущее показание</p>
              </td>
              <td class="text-start border-y">
                <p class="ml-4">
                  <DeviceValues device={device} style=""></DeviceValues>
                </p>
              </td>
            </tr>
          )}
          <tr>
            <td class="text-start border-y">
              <p class="line-clamp-1">Серийный номер</p>
            </td>
            <td class="text-start border-y">
              <p class="ml-4">{device?.serial_number}</p>
            </td>
          </tr>
          <tr>
            <td class="text-start border-y">
              <p class="line-clamp-1">Токен</p>
            </td>
            <td class="text-start border-y">
              <p class="ml-4">{device?.unique_id}</p>
            </td>
          </tr>
          {device?.output && (
            <tr>
              <td class="text-start border-y">
                <p class="line-clamp-1">Тип выхода</p>
              </td>
              <td class="text-start border-y">
                <p class="ml-4">{device?.output ? device?.output : '-'}</p>
              </td>
            </tr>
          )}
          {device?.last_event?.at && (
            <tr>
              <td class="text-start border-y">
                <p class="line-clamp-1">Последний выход на связь</p>
              </td>
              <td class="text-start border-y">
                <p class="ml-4">
                  {Math.round((Date.now() - new Date(device?.last_event?.at)) / 60 / 1000 / 60) === 0
                    ? 'менее часа назад'
                    : Math.round((Date.now() - new Date(device?.last_event?.at)) / 60 / 1000 / 60) + ' часов назад'}
                </p>
              </td>
            </tr>
          )}
          <tr>
            <td class="text-start border-y">
              <p class="line-clamp-1">Производитель</p>
            </td>
            <td class="text-start border-y">
              <p class="ml-4">{device?.manufacturer}</p>
            </td>
          </tr>
          <tr>
            <td class="text-start border-y">
              <p class="line-clamp-1">Дата производства</p>
            </td>
            <td class="text-start border-y">
              <p class="ml-4">-</p>
            </td>
          </tr>
          <tr>
            <td class="text-start border-y">
              <p class="line-clamp-1">Прошивка ПО</p>
            </td>
            <td class="text-start border-y">
              <p class="ml-4">-</p>
            </td>
          </tr>
          <tr>
            <td class="text-start border-y">
              <p class="line-clamp-1">Начальное значение</p>
            </td>
            <td class="text-start border-y">
              <p class="ml-4">-</p>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default DeviceCard;
