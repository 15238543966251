import React from 'react';
import DeviceList from './DeviceList';
import Modal from '../components/Modal';
import { getDevicesApi, addDeviceApi, bindObjDevApi, getObjectsApi } from '../api/api';
import AlertAccent from '../components/AlertAccent';
import BtnFilled from '../components/BtnOutlined';
import DeviceAdder from './DeviceAdder';
import LoadingSpinner from '../components/LoadingSpinner';
import { ToastContainer, toast } from 'react-toastify';
import AddIcon from '@mui/icons-material/Add';
import 'react-toastify/dist/ReactToastify.css';

import { empty, zero } from '../regex';

const innitialDevs = [
  {
    device_type: 'counter',
    category: 'Счетчики',
    device_type_i: 0,
    sub: [
      {
        device_type: 'counter:water:cold',
        category: 'Счетчик холодной воды (ХВС)',
        device_subtype_i: 0,
        output_type: [
          {
            i: 0,
            key: 'relay',
            name: 'Геркон',
          },
          {
            i: 1,
            key: 'namur',
            name: 'Намур',
          },
        ],
      },
      {
        device_type: 'counter:water:hot',
        category: 'Счетчик горячей воды (ГВС)',
        device_subtype_i: 1,
        output_type: [
          {
            i: 0,
            key: 'relay',
            name: 'Геркон',
          },
          {
            i: 1,
            key: 'namur',
            name: 'Намур',
          },
        ],
      },
      {
        device_type: 'counter:water',
        category: 'Счетчик универсальный',
        device_subtype_i: 2,
        output_type: [
          {
            i: 0,
            key: 'relay',
            name: 'Геркон',
          },
          {
            i: 1,
            key: 'namur',
            name: 'Намур',
          },
        ],
      },
    ],
  },
  {
    device_type: 'sensor',
    category: 'Датчики',
    device_type_i: 1,
    sub: [
      {
        device_type: 'sensor:water:leakage',
        category: 'Датчик протечки',
        device_subtype_i: 0,
        output_type: [
          {
            i: 0,
            key: 'ws',
            name: 'Датчик протечки (без КО)',
          },
          {
            i: 1,
            key: 'wsfc',
            name: 'Датчик протечки (с КО)',
          },
        ],
      },
      {
        device_type: 'sensor:temperature',
        category: 'Датчик температуры',
        device_subtype_i: 1,
        output_type: [
          {
            i: 0,
            key: 'pt1000',
            name: 'Pt1000',
          },
        ],
      },
    ],
  },
  {
    device_type: 'controller',
    category: 'Контроллеры',
    device_type_i: 2,
    sub: [
      {
        device_type: null,
        category: 'Контроллер стандартный',
        device_subtype_i: 0,
        output_type: [
          {
            i: 0,
            key: null,
            name: '',
          },
        ],
      },
    ],
  },
];

const ObjectsController = ({ user, devices, setDevices }) => {
  const [showAddModal, setShowAddModal] = React.useState(false);
  const [showWarningModal, setShowWarningModal] = React.useState(false);
  const [accordion, setAccordion] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [objectId, setObjectId] = React.useState({});
  const [validationErrors, setValidationErrors] = React.useState([]);

  const getDevices = async () => {
    await getDevicesApi(
      function (data) {
        console.log(data);
        setDevices(data);
      },
      function (err) {
        toast.error(err.error, { hideProgressBar: true });
      }
    );
  };

  const devToAddDefault = {
    device_type: innitialDevs[0].device_type,
    device_sub_type: innitialDevs[0].sub[0].device_type,
    manufacturer: 'Meridian',
    serial_number: 'A1000000',
    user_id: user.id,
    output_type: innitialDevs[0].sub[0].output_type[0].key,
  };

  const [deviceToAdd, setDeviceToAdd] = React.useState(devToAddDefault);

  const addDevice = async () => {
    await addDeviceApi(
      deviceToAdd,
      async function (data) {
        await bindObjDev(data);
        await getDevices();
      },
      function (err) {
        toast.error(err.error, { hideProgressBar: true });
      }
    );
  };

  const bindObjDev = async (data) => {
    let bindJson = { key: data.key, object_id: objectId.object_id };
    await bindObjDevApi(
      bindJson,
      function (data) {
        setShowAddModal(false);
      },
      function (err) {
        toast.error(err.error, { hideProgressBar: true });
      }
    );
  };

  const validateDeviceToAdd = () => {
    setValidationErrors([]);
    var ret = true;
    if (empty.test(deviceToAdd.serial_number)) {
      setValidationErrors((p) => [...p, { error: 'Серийный номер пустой' }]);
      ret = false;
    }
    if (empty.test(deviceToAdd.manufacturer)) {
      setValidationErrors((p) => [...p, { error: 'Производитель пустой' }]);
      ret = false;
    }
    return ret;
  };

  React.useEffect(() => {
    const get = async () => {
      setLoading(true);
      await getDevices();
      await getObj();
      setLoading(false);
    };
    get();
  }, []);

  const addDev = async () => {
    if (validateDeviceToAdd()) {
      await addDevice();
    }
  };

  const add = async () => {
    setDeviceToAdd(devToAddDefault);
    setValidationErrors([]);
    setShowAddModal(true);
  };

  const [objects, setObjects] = React.useState([]);
  const getObj = async () => {
    setLoading(true);
    await getObjectsApi(
      function (data) {
        setLoading(false);
        if (data && data.length !== 0) {
          setObjects(data);
          setObjectId({ ...objectId, object_id: data[0].id });
        }
      },
      function (err) {
        toast.error(err.error, { hideProgressBar: true });
        setLoading(false);
      }
    );
  };

  return (
    <>
      <div>
        <ToastContainer />
      </div>
      <div class="sm:flex min-h-screen items-start justify-center bg-gradient-to-r p-2 sm:p-4 from-[#ededed] to-[#dcdcdc]">
        {!loading ? (
          <>
            <div class="w-full max-w-sm sm:max-w-sm md:max-w-xl lg:max-w-2xl xl:max-w-4xl 2xl:max-w-5xl">
              <div className="flex items-center space-x-4 my-4">
                <p className="truncate text-3xl font-normal">Список устройств</p>
                <div
                  onClick={() => (objects.length !== 0 ? add() : setShowWarningModal(true))}
                  className="flex items-center justify-center bg-orange-500 hover:bg-orange-600 rounded-full p-1"
                  style={{ width: 'fit-content' }}>
                  <AddIcon className="text-white w-6 sm:w-6 xl:w-8 2xl:w-8 h-6 sm:h-6 xl:h-7 2xl:h-7" />
                </div>
              </div>

              <div>
                <DeviceList accordion={accordion} setAccordion={setAccordion} devices={devices} user={user} getDevices={getDevices}></DeviceList>
              </div>
            </div>

            <Modal
              showModal={showAddModal && user.verified_at}
              setShowModal={setShowAddModal}
              saveCb={() => addDev()}
              title="Добавление устройства"
              error={validationErrors}>
              <DeviceAdder
                setDevice={setDeviceToAdd}
                device={deviceToAdd}
                innitialDevs={innitialDevs}
                objects={objects}
                objectId={objectId}
                setObjectId={setObjectId}></DeviceAdder>
            </Modal>

            <Modal showModal={showWarningModal} setShowModal={setShowWarningModal} title="Добавление объекта">
              <AlertAccent title="Невозможно добавить устройство!" text="Вначале добавьте хотя бы один объект" showAlert={true}></AlertAccent>
            </Modal>

            <Modal showModal={showAddModal && !user.verified_at} setShowModal={setShowAddModal} title="Добавление объекта">
              <AlertAccent
                title="Невозможно добавить устройство!"
                text="Аккаунт не подтвержден. Для подтверждения аккаунта перейдите пожалуйста по ссылке, отправленной на Вашу почту"
                showAlert={true}></AlertAccent>
            </Modal>
          </>
        ) : (
          <LoadingSpinner loading={true}></LoadingSpinner>
        )}
      </div>
    </>
  );
};

export default ObjectsController;
