import React from 'react';

import Input from '../components/Input';

const ObjectEditor = ({ setObject, object }) => {
  return (
    <>
      <div class="w-full">
        <Input
          value={object.address}
          inputCb={(e) => setObject({ ...object, address: e.target.value })}
          label="Адрес"
          id="addr"
          type="text"
          placeholder={'Окружная 6 кв 96'}></Input>
      </div>
      <div class="w-full mt-4">
        <Input
          value={object.apartment}
          inputCb={(e) => setObject({ ...object, apartment: parseInt(e.target.value) })}
          label="Номер дома или квартиры"
          id="apartment"
          type="number"
          placeholder={'96'}></Input>
      </div>
      <div class="w-full mt-4">
        <Input
          value={object.payment_code}
          inputCb={(e) => setObject({ ...object, payment_code: e.target.value })}
          label="Код плательщика"
          id="code"
          type="text"
          placeholder={'6848'}></Input>
      </div>
    </>
  );
};

export default ObjectEditor;
