import React, { useState, useEffect, useRef, useCallback } from 'react';

//remove-all
//no-tailwindcss
//no-tailwindcss-base
//https://stackoverflow.com/questions/61039259/disable-tailwind-on-a-div-or-component
//prose prose-lg

const HtmlViewer = ({ html }) => {
  return (
    <div>
      <div class="no-tailwindcss-base" dangerouslySetInnerHTML={{ __html: html.html }}></div>
    </div>
  );
};

export default HtmlViewer;
