import React from 'react';

const LastEventInfo = ({ device }) => {
  const lastEventInfo = React.useMemo(() => {
    const lastEventDate = new Date(device?.last_event?.at);
    if (isNaN(lastEventDate)) return 'Устройство не выходило на связь';
    const hoursAgo = Math.round((Date.now() - lastEventDate) / 3600000);
    if (hoursAgo < 24) {
      return hoursAgo === 0 ? 'Был в сети: менее часа назад' : `Был в сети: ${hoursAgo} ${hoursAgo === 1 ? 'час' : 'часов'} назад`;
    } else {
      const daysAgo = Math.round(hoursAgo / 24);
      if (daysAgo <= 30) {
        return `Был в сети: ${daysAgo} ${
          daysAgo % 10 === 1 && daysAgo % 100 !== 11
            ? 'день'
            : daysAgo % 10 >= 2 && daysAgo % 10 <= 4 && (daysAgo % 100 < 10 || daysAgo % 100 >= 20)
            ? 'дня'
            : 'дней'
        } назад`;
      } else {
        const monthsAgo = Math.round(daysAgo / 30);
        return `Был в сети: ${monthsAgo} ${monthsAgo === 1 ? 'месяц' : 'месяцa'} назад`;
      }
    }
  }, [device?.last_event?.at]);

  return (
    <div>{lastEventInfo}</div>
  );
};

export default LastEventInfo;