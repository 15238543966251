import React from 'react';
import ScrollTabs from '../components/ScrollTabs';
import ObjectEditor from './ObjectEditor';
import ObjectDeviceBinder from './ObjectDeviceBinder';
import DeviceList from './DeviceList';
import Modal from '../components/Modal';
import LoadingSpinner from '../components/LoadingSpinner';
import ConfirmAlert from '../components/ConfirmAlert';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import WaterDropIcon from '@mui/icons-material/WaterDrop';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import AddIcon from '@mui/icons-material/Add';
import { empty, zero } from '../regex';
import { getObjectsApi, addObjectApi, editObjectApi, deleteObjectApi } from '../api/api';
import AlertAccent from '../components/AlertErrorBox';

import BtnFilled from '../components/BtnOutlined';

const ObjectsController = ({ user, objects, setObjects }) => {
  const [objectToEditOrAdd, setObjectToEditOrAdd] = React.useState({ user_id: user.id, address: '', apartment: '', payment_code: '' });
  const [validationErrors, setValidationErrors] = React.useState([]);
  const [showModal, setShowModal] = React.useState(false);
  const [showDelModal, setShowDelModal] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    const fetchData = async () => {
      await getObjects();
    };
    fetchData();
  }, []);

  const getObjects = async () => {
    setLoading(true);
    await getObjectsApi(
      function (data) {
        setObjects(data);
        setLoading(false);
      },
      function (err) {
        toast.error(err.error, { hideProgressBar: true });
        setLoading(false);
      }
    );
  };

  const addObject = async () => {
    setLoading(true);
    await addObjectApi(
      objectToEditOrAdd,
      function (data) {
        setLoading(false);
        setShowModal(false);
      },
      function (err) {
        toast.error(err.error, { hideProgressBar: true });
        setLoading(false);
      }
    );
  };

  const editObject = async () => {
    setLoading(true);
    await editObjectApi(
      objectToEditOrAdd,
      function (data) {
        setLoading(false);
        setShowModal(false);
      },
      function (err) {
        toast.error(err.error, { hideProgressBar: true });
        setLoading(false);
      }
    );
  };

  const deleteObject = async () => {
    setLoading(true);
    await deleteObjectApi(
      objectToEditOrAdd.id,
      function (data) {
        setLoading(false);
        setShowDelModal(false);
      },
      function (err) {
        toast.error(err.error, { hideProgressBar: true });
        setLoading(false);
      }
    );
  };

  const validateObject = (object) => {
    const errors = [];
    if (empty.test(object.address)) {
      errors.push({ error: 'Адрес пустой' });
    }
    if (!zero.test(object.apartment)) {
      errors.push({ error: 'Номер дома пустой' });
    }
    if (empty.test(object.payment_code)) {
      errors.push({ error: 'Код плательщика пустой' });
    }
    return errors;
  };

  const addObjectHandler = async () => {
    const errors = validateObject(objectToEditOrAdd);
    if (errors.length === 0) {
      await addObject();
      await getObjects();
    } else {
      setValidationErrors(errors);
    }
  };

  const editObjectHandler = async () => {
    const errors = validateObject(objectToEditOrAdd);
    if (errors.length === 0) {
      await editObject();
      await getObjects();
    } else {
      setValidationErrors(errors);
    }
  };

  const deleteObjectHandler = async () => {
    await deleteObject();
    await getObjects();
  };

  const add = async () => {
    setValidationErrors([]);
    setObjectToEditOrAdd({ user_id: user.id, address: '', apartment: '', payment_code: '' });
    setShowModal(true);
  };

  const edit = async (object) => {
    setValidationErrors([]);
    setObjectToEditOrAdd(object);
    setShowModal(true);
  };

  const del = async (object) => {
    setObjectToEditOrAdd(object);
    setShowDelModal(true);
  };

  return (
    <>
      <div>
        <ToastContainer />
      </div>
      <div className="sm:flex min-h-screen items-start justify-center bg-gradient-to-r p-2 sm:p-4 from-[#ededed] to-[#dcdcdc]">
        <div className="w-full max-w-sm sm:max-w-sm md:max-w-xl lg:max-w-2xl xl:max-w-4xl 2xl:max-w-5xl">
          {!loading ? (
            <>
              <div className="flex items-center space-x-4 my-4">
                <p className="truncate text-3xl font-normal">Объекты</p>
                <div
                  onClick={() => add()}
                  className="flex items-center justify-center bg-orange-500 hover:bg-orange-600 rounded-full p-1"
                  style={{ width: 'fit-content' }}>
                  <AddIcon className="text-white w-6 sm:w-6 xl:w-8 2xl:w-8 h-6 sm:h-6 xl:h-7 2xl:h-7" />
                </div>
              </div>
              <>
                {objects.length !== 0 && (
                  <div className="bg-white shadow-3xl rounded-3xl p-4">
                    <>
                      <div className="text-sm font-medium text-center text-gray-800 border-gray-200 dark:text-gray-400 dark:border-gray-700">
                        <div className="grid grid-cols-6 sm:grid-cols-12 gap-4">
                          <div className="col-span-2 sm:col-span-5 text-left text-base font-normal text-gray-800 pb-2">Адрес</div>
                          <div className="col-span-2 sm:col-span-5 text-left text-base font-normal text-gray-800 pb-2 line-clamp-1">Код плательщика</div>
                          <div className="col-span-1 sm:col-span-1 text-center pb-2"></div>
                          <div className="col-span-1 sm:col-span-1 text-center pb-2"></div>
                          {objects.map((object, index) => (
                            <React.Fragment key={index}>
                              <div className="col-span-2 sm:col-span-5 text-left text-lg sm:text-xl font-normal py-2">
                                <p className="line-clamp-1">{object.address + ', ' + object.apartment}</p>
                              </div>
                              <div className="col-span-2 sm:col-span-5 text-left text-lg sm:text-xl font-normal py-2">
                                <p className="line-clamp-1">{object.payment_code}</p>
                              </div>
                              <div className="col-span-1 sm:col-span-1 text-center py-2 flex items-center justify-center">
                                <div
                                  onClick={() => edit(object)}
                                  className="flex items-center justify-center bg-orange-500 hover:bg-orange-600 rounded-full p-1"
                                  style={{ width: 'fit-content' }}>
                                  <ModeEditOutlineOutlinedIcon className="text-white w-6 sm:w-6 xl:w-8 2xl:w-8 h-6 sm:h-6 xl:h-7 2xl:h-7" />
                                </div>
                              </div>
                              <div className="col-span-1 sm:col-span-1 text-center py-2 flex items-center justify-center">
                                <div
                                  onClick={() => del(object)}
                                  className="flex items-center justify-center bg-orange-500 hover:bg-orange-600 rounded-full p-1"
                                  style={{ width: 'fit-content' }}>
                                  <DeleteForeverOutlinedIcon className="text-white w-6 sm:w-6 xl:w-8 2xl:w-8 h-6 sm:h-6 xl:h-7 2xl:h-7" />
                                </div>
                              </div>
                            </React.Fragment>
                          ))}
                        </div>
                      </div>
                    </>
                  </div>
                )}
              </>
            </>
          ) : (
            <LoadingSpinner loading={true}></LoadingSpinner>
          )}
        </div>

        <Modal showModal={showModal && !user.verified_at} setShowModal={setShowModal} title="Добавление/Редактирование объекта">
          <AlertAccent
            title="Невозможно добавить/редактировать объект!"
            text="Аккаунт не подтвержден. Для подтверждения аккаунта перейдите пожалуйста по ссылке, отправленной на Вашу почту"
            showAlert={true}></AlertAccent>
        </Modal>

        <Modal
          showModal={showModal && user.verified_at}
          setShowModal={setShowModal}
          saveCb={() => {
            if (objectToEditOrAdd.id) {
              editObjectHandler();
            } else {
              addObjectHandler();
            }
          }}
          title={objectToEditOrAdd.id ? 'Редактирование объекта' : 'Добавление объекта'}
          error={validationErrors}>
          <ObjectEditor setObject={setObjectToEditOrAdd} object={objectToEditOrAdd}></ObjectEditor>
        </Modal>

        <ConfirmAlert showAlert={showDelModal} setShowAlert={setShowDelModal} delCb={() => deleteObjectHandler()} title="Удаление объекта">
          <AlertAccent text="Вы действительно хотите удалить объект?" showAlert={true}>
            <p>{'Адрес: ' + objectToEditOrAdd.address + ', ' + objectToEditOrAdd.apartment}</p>
            <p>{'Все привязанные к данному объекту устройства также будут удалены!'}</p>
          </AlertAccent>
        </ConfirmAlert>
      </div>
    </>
  );
};

export default ObjectsController;
