import React from 'react';

const Dropdown = React.memo(({ options, value, onChange, id, valkey, optkey, disabled = false, emptyoption }) => {
  const handleChange = React.useCallback((e) => {
    onChange(e.target.value);
  }, [onChange]);

  return (
    <form className="flex flex-row items-center mt-2">
      <select
        disabled={disabled}
        value={value}
        onChange={handleChange}
        className={`text-gray-800 bg-white text-sm leading-tight h-6 rounded-full outline-none text-left ${disabled ? 'text-gray-400' : ''}`}
        id={id}
        style={{
          width: 'auto', // Автоматическая ширина
          padding: '0', // Убраны отступы
          margin: '0', // Убраны отступы
          textAlign: 'left', // Текст выровнен по левому краю
          paddingRight: '1.5rem', // Увеличенный отступ справа
          background: `url('data:image/svg+xml;utf8,<svg fill="%23000" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/></svg>') no-repeat right 0.1rem center`, // Стрелочка сдвинута на 0.5rem вправо
          WebkitAppearance: 'none', // Убираем стандартное оформление для select в WebKit браузерах
          MozAppearance: 'none', // Убираем стандартное оформление для select в Firefox
          appearance: 'none', // Убираем стандартное оформление для select
          direction: 'rtl'
        }}
      >
        {options.length === 0 && <option>{emptyoption}</option>}
        {options.map((opt) => (
          <option key={opt[valkey]} value={opt[valkey]}>
            {opt[optkey]}
          </option>
        ))}
      </select>
    </form>
  );
});

export default Dropdown;
