import axios from 'axios';
import qs from 'qs'; // Добавьте эту строку

const axiosRequest = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL_YANDEX,
  headers: { 'Content-Type': 'application/x-www-form-urlencoded' }, // Добавьте эту строку
});

export const linkAccountsApi = async (params, dataCb, errCb) => {
  await axiosRequest
    .post('auth/login', qs.stringify(params)) // Измените эту строку
    .then((res) => {
      dataCb(res.data);
    })
    .catch((err) => {
      console.log(err);
      var msg = err?.response?.data;
      if (msg) {
        errCb(msg);
      } else {
        errCb({ error: err?.name + ' ' + err?.message, type: 'ServerError' });
      }
    });
};

